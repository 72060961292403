export const generateQueries = (values, config, prefix) => {
  let aQueries = [],
    nOrCounter = 0;

  config.forEach(({ name, or, type }) => {
    const aFields = name.split(',');

    aFields.forEach(sField => {
      if (values[name] === 0 || (values[name] && values[name] !== '')) {
        if (or) {
          aQueries.push(
            `$or[${nOrCounter++}][${sField}][$like]=%${values[name]}%`
          );
        } else {
          if (type === 'input') {
            let sFieldName = prefix ? `${prefix}[${sField}]` : sField;
            aQueries.push(`${sFieldName}[$like]=%${values[sField]}%`);
          } else if (type === 'date') {
            let sFieldName = prefix ? `${prefix}[date]` : 'date';
            if (sField.indexOf('_ini') > -1) {
              aQueries.push(
                `${sFieldName}[$gte]=${values[sField].format(
                  'YYYY-MM-DD'
                )} 00:00:00`
              );
            } else if (sField.indexOf('_end') > -1) {
              aQueries.push(
                `${sFieldName}[$lte]=${values[sField].format(
                  'YYYY-MM-DD'
                )} 23:59:59`
              );
            }
          } else {
            let sFieldName = prefix ? `${prefix}[${sField}]` : sField;
            aQueries.push(`${sFieldName}=${values[sField]}`);
          }
        }
      }
    });
  });

  return aQueries.join('&');
};

export const generateSorts = (sorter = []) => {
  let aSorters = [];
  for (let single of sorter) {
    if (single.order) {
      for (let key of single.columnKey.split(',')) {
        aSorters.push(
          `$sort[${key}]=${single.order === 'ascend' ? '1' : '-1'}`
        );
      }
    }
  }

  return aSorters.join('&');
};

export const fnResetValidations = (entity, setFields) => {
  let aFields = [];

  for (let sKey in entity) {
    aFields[sKey] = {
      status: null,
      help: null,
    };
  }

  setFields(aFields);
  return aFields;
};

export const skipSameValues = (oOriginal, oChanges) => {
  let oResult = Array.isArray(oOriginal) ? [] : {},
    oTemp = Array.isArray(oChanges) ? [] : {};

  for (let sKey in oChanges) {
    // Modificación para arreglos, comparar los elementos de un arreglo
    if (Array.isArray(oChanges[sKey])) {
      if (oOriginal[sKey].length === oChanges[sKey].length) {
        oTemp = skipSameValues(oOriginal[sKey], oChanges[sKey]);
      } else {
        oTemp = oChanges[sKey];
      }
      if (Object.keys(oTemp).length !== 0) {
        oResult[sKey] = oTemp;
      }
    } else if (typeof oChanges[sKey] === 'object') {
      if (oOriginal[sKey]) {
        oTemp = skipSameValues(oOriginal[sKey], oChanges[sKey]) || null;
      }
      if (Object.keys(oTemp).length !== 0) {
        oResult[sKey] = oTemp;
      }
    } else {
      if (oChanges[sKey] !== oOriginal[sKey]) {
        oResult[sKey] = oChanges[sKey];
      }
    }
  }

  if (Object.keys(oResult).length === 1 && Object.keys(oResult)[0] === 'id') {
    oResult = {};
  }
  return oResult;
};
