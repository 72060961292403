import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Upload, message, Select, Form } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { useAuth, useCompany } from '../../hooks';
import { getServer } from '../../utils/url';

const { Dragger } = Upload;
const { Option } = Select;

export const UploadFiles = ({ onCancel, visible }) => {
  const [{ token }] = useAuth();
  const [oCompany] = useCompany();
  const [fileList, setFileList] = useState([]);
  const [company, setCompany] = useState('');

  const props = {
    fileList,
    accept: '.txt',
    name: 'files',
    multiple: true,
    action: `${getServer()}/upload?token=${token}&company=${company}`,
    onChange: info => {
      const { status } = info.file;
      setFileList(info.fileList);
      if (status === 'done') {
        if (
          info.fileList.filter(item => item.status === 'done').length ===
          info.fileList.length
        ) {
          setFileList([]);
        }
        message.success('Se ha subido el archivo correctamente');
      } else if (status === 'error') {
        for (const single of info.fileList) {
          const { response } = single;

          message.error(response.message || 'Error subiendo el archivo');
        }
      }
    },
  };

  return (
    <Modal
      centered
      destroyOnClose
      okText="Cerrar"
      okType=""
      onOk={onCancel}
      cancelButtonProps={{
        style: {
          display: 'none',
        },
      }}
      title="Subir archivos"
      visible={visible}
      width={800}
    >
      <Form layout="horizontal">
        <Form.Item label="Selecciona la empresa">
          <Select
            placeholder="Seleccione"
            onChange={value => setCompany(value)}
          >
            {oCompany?.data.map(oItem => (
              <Option key={oItem.id} value={oItem.id}>
                {oItem.business_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Da clic o arrastra los archivos en esta area para subir
        </p>
        <p className="ant-upload-hint">
          Los archivos se subirán automáticamente
        </p>
      </Dragger>
    </Modal>
  );
};

UploadFiles.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
};
