import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/charts';

export const PieChart = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Pie
      angleField="total"
      appendPadding={10}
      colorField="name"
      color={data.map(item => item.color)}
      data={data}
      label={{
        type: 'outer',
        content: ref => {
          return `${ref.name} (${ref?.total
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`;
        },
      }}
      meta={{
        total: {
          alias: 'Total',
          formatter: value =>
            value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        },
        name: { alias: 'Tipo de Comprobante' },
      }}
      radius={0.9}
    />
  );
};

PieChart.propTypes = {
  data: PropTypes.array,
};
