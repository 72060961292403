import { create } from 'apisauce';
import { store } from '../redux/store';
import { getApiKey, getServer } from '../utils/url';
import { Auth } from '../redux/reducers/auth';

export const GET = 'GET';
export const FIND = 'FIND';
export const SAVE = 'POST';
export const UPDATE = 'PATCH';
export const DELETE = 'DELETE';

export const token = () => store.getState().auth.token;

const API = create({
  baseURL: getServer(),
});
const monitor = response => {
  if (response.status === 401) {
    store.dispatch(Auth.authClear());
  }
};

API.addMonitor(monitor);

export const login = async (email, password) =>
  await API.post('/auth', { email, password, strategy: 'local' });

export const forgot = email =>
  API.post(
    '/forgot',
    { email },
    {
      headers: {
        'x-api-key': getApiKey(),
      },
    }
  );

export const patchPassword = async (fToken, payload) =>
  await API.patch(`/forgot/${fToken}`, payload, {
    headers: {
      'x-api-key': getApiKey(),
    },
  });

export const createVerify = async payload =>
  await API.post(`/verify`, payload, {
    headers: {
      'x-api-key': getApiKey(),
    },
  });

/**
 * Generic function to make api calls
 * @param {ENUM} operation - Consts FIND, GET, SAVE or UPDATE
 * @param {string} model - Model name in API
 * @param {object} payload - Data to send
 * @param {object} params - Object that can contain id of model or queries and skip for find calls.
 */
export async function process(operation, model, payload = {}, params = {}) {
  const { id, queries, limit, skip } = params || {};
  const oAuth = {
    headers: {
      Authorization: `BEARER ${token()}`,
    },
  };

  switch (operation) {
    case FIND:
      return await API.get(
        `/${model}?${queries ? queries + '&' : ''}${
          limit ? '$limit=' + limit : ''
        }&$skip=${skip}`,
        null,
        oAuth
      );
    case GET:
      return await API.get(`/${model}/${id}${queries || ''}`, null, oAuth);
    case SAVE:
      return await API.post(`/${model}`, payload, oAuth);
    case UPDATE:
      return await API.patch(`/${model}/${id}`, payload, oAuth);
    case DELETE:
      return await API.delete(`/${model}/${id}`, null, oAuth);
    default:
      return null;
  }
}
