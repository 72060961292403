import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const aSearchElements = aCompanies => [
  {
    label: 'Empresa',
    name: 'company_id',
    type: 'select',
    values: () =>
      aCompanies.map(oCompany => (
        <Option key={oCompany.id} value={oCompany.id}>
          {oCompany.business_name}
        </Option>
      )),
    visible: aCompanies.length > 1,
  },
  {
    label: 'Razón Social',
    name: 'business_name',
    type: 'input',
  },
  {
    label: 'RFC',
    name: 'rfc',
    type: 'input',
  },
  {
    label: 'Estatus',
    name: 'status',
    type: 'select',
    // eslint-disable-next-line react/display-name
    values: () => (
      <>
        <Option value={1}>Activo</Option>
        <Option value={0}>Inactivo</Option>
      </>
    ),
  },
];

export const oInitialSearch = {
  business_name: '',
  company_id: '',
  rfc: '',
  status: undefined,
};

export const oInitialState = {
  address: '',
  business_name: '',
  company_id: '',
  ext_no: '',
  int_no: '',
  rfc: '',
  status: 0,
};
