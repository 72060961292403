import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { process, FIND } from '../services/Api';

export const useSource = (
  queries = '',
  sort = '$sort[date]=-1',
  skip = 0,
  limit = 10
) => {
  const [sources, setSources] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit, queries, sort, skip });
  const dispatch = useDispatch();

  const getSources = useCallback(async () => {
    setLoading(true);
    let oSendParams = {
      ...params,
    };
    if (oSendParams.sort) {
      if (oSendParams.queries) {
        oSendParams.queries += `&${oSendParams.sort}`;
      } else {
        oSendParams.queries = oSendParams.sort;
      }
    }
    const oResponse = await process(FIND, 'sources', {}, oSendParams);

    setLoading(false);
    if (oResponse.ok) {
      setSources(oResponse.data);
    } else {
      message.error('Error al cargar');
    }
  }, [dispatch, process, params]);

  useEffect(() => {
    getSources();
  }, [getSources]);

  const onChangeParams = (q = '', st = '', s = 0, l = 10) => {
    setParams({ limit: l, queries: q, sort: st, skip: s });
  };

  const update = () => getSources();

  return [{ ...params, ...sources }, loading, onChangeParams, update];
};
