import React, { useState } from 'react';
import { Alert, Button, Col, Form, Input, Layout, Row, Spin } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/Auth.hook';
const { Content } = Layout;
const logo = require('../../assets/images/logo.png').default;

export const LoginScreen = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [error] = useState('');
  const [{ loading }, auth] = useAuth();

  const onLogin = async () => {
    if (user !== '' && password !== '') {
      await auth(user, password);
    }
  };

  return (
    <Layout>
      <Content className="login-screen">
        {error && <Alert message={error} type="error" />}
        <Row className="content-columns" style={{ height: '100vh' }}>
          <Col className="login-form" span={12}>
            <div className="spacer">
              <Row className="logo" align="middle" justify="center">
                <Col>
                  <img src={logo} alt="" />
                </Col>
                <Col>
                  <h3>FacturaXion</h3>
                </Col>
              </Row>
              <Form onFinish={onLogin}>
                <Form.Item
                  onChange={({ target }) => setUser(target.value)}
                  name="user"
                  rules={[{ required: true, message: 'Ingresa el Usuario!' }]}
                  value={user}
                >
                  <Input placeholder="Usuario" prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  onChange={({ target }) => setPassword(target.value)}
                  name="password"
                  rules={[{ required: true, message: 'Ingresa la Password!' }]}
                >
                  <Input.Password
                    placeholder="Contraseña"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary" block>
                    {loading ? <Spin /> : 'Ingresar'}
                  </Button>
                </Form.Item>
                <span className="recover">
                  Recupera tu contraseña&nbsp;<a href="/forgot">aquí</a>
                </span>
              </Form>
            </div>
          </Col>
          <Col className="wrapper" span={12}>
            <div />
            <div />
            <div />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
