import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PublicRoute = ({ component: Component, token, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!token || token === '') {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/home',
                state: {
                  key: 'home',
                },
              }}
            />
          );
        }
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  token: PropTypes.string,
};
