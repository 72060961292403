import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Form, Input, message, Modal, Row, Select } from 'antd';
import { useGetInputData } from '../../hooks/Common.hook';
import {
  fnResetValidations,
  generateQueries,
  skipSameValues,
} from '../../utils/functions';
import { useClient, useCompany } from '../../hooks';
import { SearchSelector } from '..';
import { debounce } from 'lodash';

const { Option } = Select;

const aCompanySeach = [
  {
    name: 'business_name',
    type: 'input',
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const ClientForm = ({
  onCancel,
  onEnd,
  oInit,
  oClient,
  title,
  visible,
}) => {
  const [client, setClient] = useState(oInit);
  const [validations, setValidations] = useState([]);
  const [getFormData, getSelectValue] = useGetInputData(client, setClient);
  const resetValidations = () => fnResetValidations(client, setValidations);
  const [, loading, , , save, update] = useClient();
  const [oCompany, , companyQuery] = useCompany();

  useEffect(() => {
    if (visible) {
      setClient(oClient);
    }
  }, [visible, setClient, oClient]);

  const getUserFormData = ({ name, value }) => {
    const oUser = {
      ...client?.user,
      [name]: value,
    };

    setClient({
      ...client,
      user: oUser,
    });
  };

  const handleSearchCompanies = s => {
    if (s.length > 3) {
      let sQueries = generateQueries(
        { business_name: s, status: 1 },
        aCompanySeach
      );
      companyQuery(sQueries, 0, 50);
    }
  };

  const onSubmit = async () => {
    let aTemp = resetValidations(),
      aTempValidations = [...aTemp],
      blnValid = true,
      oSend = { ...client },
      oResponse;

    for (let sKey in oSend) {
      if (!oSend[sKey] || oSend[sKey] === '') {
        delete oSend[sKey];
      }
    }

    if (oSend.id) {
      const oUpdatedValues = skipSameValues(oClient, oSend);
      if (Object.keys(oUpdatedValues).length) {
        oResponse = await update(oClient.id, oUpdatedValues);
      }
    } else {
      if (oCompany?.data.length === 1) {
        oSend.company_id = oCompany.data[0].id;
      }
      oResponse = await save(oSend);
    }

    if (oResponse?.ok) {
      message.success(oClient.id ? 'Cliente actualizado!' : 'Cliente creado');
      onEnd();
    } else {
      const { data } = oResponse || {};
      blnValid = false;
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map(oError => {
            let aKeyName = oError.path.split('.');
            if (aKeyName.length > 1) {
              let oPrev = {
                ...aTempValidations[aKeyName[0]],
              };
              oPrev[aKeyName[1]] = {
                help:
                  oError.type === 'unique violation'
                    ? 'No disponible! Intente con otro valor'
                    : 'Error',
                status: 'error',
              };
              aTempValidations[aKeyName[0]] = oPrev;
            } else {
              aTempValidations[aKeyName[0]] = {
                help:
                  oError.type === 'unique violation'
                    ? 'El cliente ya existe'
                    : 'Error',
                status: 'error',
              };
            }
          });
        } else {
          for (let sKey in data.errors) {
            aTempValidations[sKey.replace(',', '_')] = {
              help: data.errors[sKey],
              status: 'error',
            };
          }
        }
      }
    }

    if (!blnValid) {
      setValidations(aTempValidations);
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={onSubmit}
      title={title}
      visible={visible}
      width={800}
    >
      <Form layout="vertical" name="client-form">
        <Row gutter={[16, 16]}>
          {oCompany?.data.length > 1 && (
            <Col span={6}>
              <Form.Item
                help={validations.company_id?.help}
                label="Empresa"
                validateStatus={validations.company_id?.status}
              >
                <SearchSelector
                  getSelectValue={getSelectValue}
                  handleSearch={debounce(handleSearchCompanies, 300)}
                  name="company_id"
                  placeholder="Buscar Empresa"
                  value={client.company_id}
                >
                  {oCompany?.data.map(oItem => (
                    <Option key={oItem.id} value={oItem.id}>
                      {oItem.business_name}
                    </Option>
                  ))}
                </SearchSelector>
              </Form.Item>
            </Col>
          )}
          <Col span={6}>
            <Form.Item
              help={validations.rfc?.help}
              label="RFC"
              validateStatus={validations.rfc?.status}
            >
              <Input
                name="rfc"
                onChange={getFormData}
                placeholder="Ingrese RFC"
                value={client?.rfc}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              help={validations.business_name?.help}
              label="Razón Social"
              validateStatus={validations.business_name?.status}
            >
              <Input
                name="business_name"
                onChange={getFormData}
                placeholder="Ingrese Razón Social"
                value={client?.business_name}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" plain>
          Dirección
        </Divider>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              help={validations.address?.help}
              label="Calle"
              validateStatus={validations.address?.status}
            >
              <Input
                name="address"
                onChange={getFormData}
                placeholder="Ingrese dirección"
                value={client?.address}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.ext_no?.help}
              label="Número Exterior"
              validateStatus={validations.ext_no?.status}
            >
              <Input
                name="ext_no"
                onChange={getFormData}
                placeholder="Ingrese no. exterior"
                value={client?.ext_no}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.int_no?.help}
              label="Número Interior"
              validateStatus={validations.int_no?.status}
            >
              <Input
                name="int_no"
                onChange={getFormData}
                placeholder="Ingrese no. interior"
                value={client?.int_no}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              help={validations.city?.help}
              label="Ciudad"
              validateStatus={validations.city?.status}
            >
              <Input
                name="city"
                onChange={getFormData}
                placeholder="Ingrese la ciudad"
                value={client?.city}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.state?.help}
              label="Estado"
              validateStatus={validations?.state?.status}
            >
              <Input
                name="state"
                onChange={getFormData}
                placeholder="Ingrese el estado"
                value={client?.state}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.country?.help}
              label="País"
              validateStatus={validations?.country?.status}
            >
              <Input
                name="country"
                onChange={getFormData}
                placeholder="Ingrese el país"
                value={client?.country}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" plain>
          Usuario
        </Divider>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              help={validations?.user_email?.help}
              label="Email"
              validateStatus={validations?.user_email?.status}
            >
              <Input
                name="email"
                onChange={({ target }) => getUserFormData(target)}
                placeholder="Ingrese el email"
                value={client?.user?.email}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.user_password?.help}
              label="Contraseña"
              validateStatus={validations?.user_password?.status}
            >
              <Input
                name="password"
                onChange={({ target }) => getUserFormData(target)}
                placeholder="Ingrese contraseña"
                type="password"
                value={client?.user?.password}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.user_rpassword?.help}
              label="Confirmar Contraseña"
              validateStatus={validations?.user_rpassword?.status}
            >
              <Input
                name="rpassword"
                onChange={({ target }) => getUserFormData(target)}
                placeholder="Confirme contraseña"
                type="password"
                value={client?.user?.rpassword}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.user_username?.help}
              label="Nombre de Usuario"
              validateStatus={validations?.user_username?.status}
            >
              <Input
                name="username"
                onChange={({ target }) => getUserFormData(target)}
                placeholder="Ingrese el username"
                value={client?.user?.username}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.user_full_name?.help}
              label="Nombre Completo"
              validateStatus={validations?.user_full_name?.status}
            >
              <Input
                name="full_name"
                onChange={({ target }) => getUserFormData(target)}
                placeholder="Ingrese el Nombre completo"
                value={client?.user?.full_name}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.user_mobile?.help}
              label="Número Telefónico"
              validateStatus={validations?.user_mobile?.status}
            >
              <Input
                name="mobile"
                onChange={({ target }) => getUserFormData(target)}
                placeholder="Ingrese el número"
                type="tel"
                value={client?.user?.mobile}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ClientForm.propTypes = {
  onCancel: PropTypes.func,
  onEnd: PropTypes.func,
  oInit: PropTypes.object,
  oClient: PropTypes.object,
  title: PropTypes.string,
  visible: PropTypes.bool,
};
