import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Select } from 'antd';
import { TagSelector } from '..';
const { Option } = Select;

export const SendByEmailForm = ({
  bill,
  loading,
  onCancel,
  onSubmit,
  visible,
}) => {
  const [to, setTo] = useState([]);
  const [cc, setCC] = useState([]);
  const [aSelected, setSelected] = useState([]);
  const [aCCSelected, setCCSelected] = useState([]);

  useEffect(() => {
    if (bill) {
      if (bill?.email) {
        setSelected(bill.email.split(','));
        setTo(bill.email.split(','));
      }
    }
  }, [bill]);

  const handleSubmit = () => {
    let oSend = {
      to: aSelected,
      cc,
    };
    onSubmit(oSend);
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={handleSubmit}
      title="Enviar factura"
      visible={visible}
    >
      <Form layout="horizontal">
        <Form.Item label="Para">
          <TagSelector
            aOptions={to}
            aSelected={aSelected}
            placeholder="Ingrese correos"
            setOptions={setTo}
            setSelected={setSelected}
          >
            {to.map(sEmail => (
              <Option key={sEmail} value={sEmail}>
                {sEmail}
              </Option>
            ))}
          </TagSelector>
        </Form.Item>
        <Form.Item label="Con copia">
          <TagSelector
            aOptions={cc}
            aSelected={aCCSelected}
            placeholder="Ingrese correos"
            setOptions={setCC}
            setSelected={setCCSelected}
          >
            {cc.map(sEmail => (
              <Option key={sEmail} value={sEmail}>
                {sEmail}
              </Option>
            ))}
          </TagSelector>
        </Form.Item>
      </Form>
    </Modal>
  );
};

SendByEmailForm.propTypes = {
  bill: PropTypes.object,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
