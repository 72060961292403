import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { FIND, process, SAVE, UPDATE } from '../services/Api';

export const useCancellation = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit, queries, skip });

  const getCancellations = useCallback(async () => {
    setLoading(true);
    const oResponse = await process(FIND, 'cancellations', {}, params);

    setLoading(false);
    if (oResponse.ok) {
      setData(oResponse.data);
    } else {
      message.error('Error al cargar los tipos de cancelaciones');
    }
  }, [process, params]);

  const postCancellation = useCallback(
    async payload => {
      setLoading(true);
      const oResponse = await process(SAVE, 'cancellations', payload);
      setLoading(false);

      return oResponse;
    },
    [process, setLoading]
  );

  const patchCancellation = useCallback(
    async (sId, payload) => {
      setLoading(true);
      const oResponse = await process(UPDATE, 'cancellations', payload, {
        id: sId,
      });
      setLoading(false);

      return oResponse;
    },
    [process, setLoading]
  );

  useEffect(() => {
    getCancellations();
  }, [getCancellations]);

  const onChangeParams = (q = '', s = 0, l = 10) => {
    setParams({ limit: l, queries: q, skip: s });
  };

  const update = () => getCancellations();

  return [
    { ...params, ...data },
    loading,
    onChangeParams,
    update,
    postCancellation,
    patchCancellation,
  ];
};
