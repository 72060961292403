import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const aSearchElements = () => [
  {
    label: 'RFC',
    name: 'rfc',
    type: 'input',
  },
  {
    label: 'Nombre',
    name: 'business_name',
    type: 'input',
  },
  {
    label: 'Estatus',
    name: 'status',
    type: 'select',
    // eslint-disable-next-line react/display-name
    values: () => (
      <>
        <Option></Option>
        <Option value={1}>Activo</Option>
        <Option value={0}>Inactivo</Option>
      </>
    ),
  },
];

export const oInitialSearch = {
  business_name: '',
  rfc: '',
  status: undefined,
};

export const oInitialState = {
  business_name: '',
  short_name: '',
  rfc: '',
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  country: '',
  state: '',
  city: '',
  zip_code: '',
  address: '',
  ext_no: '',
  int_no: '',
  status: 0,
};
