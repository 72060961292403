export const aSearchElements = [
  {
    label: 'Empresa',
    name: 'company',
    type: 'input-fixed',
  },
  {
    label: 'Usuario',
    name: 'user',
    type: 'input-fixed',
  },
];

export const oInitialSearch = {
  company_id: undefined,
  user_id: undefined,
};

export const oInitialState = {
  company_id: undefined,
  user_id: undefined,
};
