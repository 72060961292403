import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Col, Form, message, Modal, Row, Select } from 'antd';
import { SearchSelector } from '..';
import { useGetInputData } from '../../hooks/Common.hook';
import { fnResetValidations, generateQueries } from '../../utils/functions';
import { useCompany, useUser, useUserCompany } from '../../hooks';

const { Option } = Select;

const aUserSearch = [
  {
    name: 'email',
    type: 'input',
  },
  {
    name: 'status',
    type: 'select',
  },
];

const aCompanySeach = [
  {
    name: 'business_name',
    type: 'input',
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const AssignForm = ({ onCancel, onEnd, oInit, title, visible }) => {
  const [assign, setAssign] = useState(oInit);
  const [validations, setValidations] = useState([]);
  const [, getSelectValue] = useGetInputData(assign, setAssign);
  const resetValidations = () => fnResetValidations(assign, setValidations);
  const [oUser, , userQuery] = useUser();
  const [, loading, , , save] = useUserCompany();
  const [oCompany, , companyQuery] = useCompany();

  const handleSearchCompanies = s => {
    if (s.length > 3) {
      let sQueries = generateQueries(
        { business_name: s, status: 1 },
        aCompanySeach
      );
      companyQuery(sQueries, 0, 50);
    }
  };

  const handleSearchUsers = s => {
    if (s.length >= 3) {
      let sQueries = generateQueries({ email: s, status: 1 }, aUserSearch);
      userQuery(sQueries, 0, 50);
    } else {
      userQuery('', 0, 50);
    }
  };

  const onSubmit = async () => {
    let aTemp = resetValidations(),
      aTempValidations = [...aTemp],
      blnValid = true,
      oSend = { ...assign };
    const oResponse = await save(oSend);

    if (oResponse?.ok) {
      message.success('Creado correctamente');
      onEnd();
    } else {
      const { data } = oResponse || {};
      blnValid = false;
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map(oError => {
            aTempValidations[oError.path] = {
              help:
                oError.type === 'unique violation'
                  ? 'Valor existente en nuestra base'
                  : 'Error',
              status: 'error',
            };
          });
        } else {
          for (let sKey in data.errors) {
            aTempValidations[sKey] = {
              help: data.errors[sKey],
              status: 'error',
            };
          }
        }
      }

      if (data?.data) {
        if (data.data?.label === 'API_USER_PASS_NOT_MATCH') {
          const oNotMatch = {
            help: 'Contraseñas diferentes',
            status: 'error',
          };
          aTempValidations['password'] = aTempValidations['rpassword'] =
            oNotMatch;
        }
      }
    }

    if (!blnValid) {
      setValidations(aTempValidations);
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={onSubmit}
      title={title}
      visible={visible}
      width={800}
    >
      <Form layout="vertical" name="user-form">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              help={validations.company_id?.help}
              label="Empresa"
              validateStatus={validations.company_id?.status}
            >
              <SearchSelector
                getSelectValue={getSelectValue}
                handleSearch={debounce(handleSearchCompanies, 300)}
                name="company_id"
                placeholder="Buscar Compañia"
                value={assign.company_id}
              >
                {oCompany?.data.map(oItem => (
                  <Option key={oItem.id} value={oItem.id}>
                    {oItem.business_name}
                  </Option>
                ))}
              </SearchSelector>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              help={validations.email?.help}
              label="Email"
              validateStatus={validations.email?.status}
            >
              <SearchSelector
                getSelectValue={getSelectValue}
                handleSearch={debounce(handleSearchUsers, 300)}
                name="user_id"
                placeholder="Buscar Usuario"
                showSearch
                value={assign.user_id}
              >
                {oUser?.data.map(oItem => (
                  <Option key={oItem.id} value={oItem.id}>
                    {oItem.email}
                  </Option>
                ))}
              </SearchSelector>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

AssignForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  oInit: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};
