import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';

const { RangePicker } = DatePicker;

export const SearchBar = ({
  elements,
  handleReset,
  handleSearch,
  search,
  setSearch,
}) => {
  const aVisibles = elements.filter(({ visible = true }) => visible);
  const nFieldsSize = Math.round(16 / aVisibles.length);

  const getDataForm = ({ name, value }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleDates = aDates => {
    if (aDates) {
      setSearch({
        ...search,
        date_ini: aDates[0],
        date_end: aDates[1],
      });
    }
  };

  return (
    <Form layout="vertical">
      <Row gutter={[20, 20]}>
        {elements
          .filter(({ visible = true }) => visible)
          .map(
            (
              {
                label,
                name,
                placeholder = '',
                type,
                values,
                sizes = { small: 12, normal: 0 },
              },
              nIndex
            ) => (
              <Col
                key={nIndex}
                xs={24}
                sm={sizes.small || 12}
                md={sizes.normal || nFieldsSize}
              >
                <Form.Item label={label}>
                  {type === 'input' || type === 'input-fixed' ? (
                    <Input
                      name={name}
                      type="text"
                      value={search[name]}
                      placeholder={placeholder}
                      onChange={({ target }) => getDataForm(target)}
                    />
                  ) : type === 'select' ? (
                    <Select
                      name={name}
                      value={search[name]}
                      placeholder={placeholder}
                      onChange={value => getDataForm({ name, value })}
                      style={{ width: '100%' }}
                    >
                      {typeof values === 'function' && values()}
                    </Select>
                  ) : type === 'date' ? (
                    <RangePicker
                      locale={locale}
                      onChange={handleDates}
                      value={[search?.date_ini, search?.date_end]}
                      style={{ width: '100%' }}
                    />
                  ) : null}
                </Form.Item>
              </Col>
            )
          )}
        <Col flex="auto">
          <Form.Item label="&nbsp;">
            <Button onClick={() => handleSearch()}>
              <SearchOutlined />
            </Button>
            {handleReset && (
              <Button style={{ marginLeft: 10 }} onClick={handleReset}>
                <DeleteOutlined />
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

SearchBar.propTypes = {
  elements: PropTypes.array.isRequired,
  handleReset: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  setSearch: PropTypes.func.isRequired,
};
