import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, message, Modal, Row, Select } from 'antd';
import { useGetInputData } from '../../hooks/Common.hook';
import { fnResetValidations, skipSameValues } from '../../utils/functions';
import { useCancellation } from '../../hooks';

const { Option } = Select;

export const CancellationForm = ({
  onCancel,
  onEnd,
  oInit,
  oCancellation,
  title,
  visible,
}) => {
  const [cancellation, setCancellation] = useState(oInit);
  const [validations, setValidations] = useState([]);
  const [getFormData, getSelectValue] = useGetInputData(
    cancellation,
    setCancellation
  );
  const resetValidations = () =>
    fnResetValidations(cancellation, setValidations);
  const [, loading, , , save, update] = useCancellation();

  useEffect(() => {
    if (visible) {
      setCancellation(oCancellation);
    }
  }, [visible, setCancellation, oCancellation]);

  const onSubmit = async () => {
    let aTemp = resetValidations(),
      aTempValidations = [...aTemp],
      blnValid = true,
      oSend = { ...cancellation },
      oResponse;

    for (let sKey in oSend) {
      if (!oSend[sKey] || oSend[sKey] === '') {
        delete oSend[sKey];
      }
    }

    if (oSend.id) {
      const oUpdatedValues = skipSameValues(oCancellation, oSend);
      if (Object.keys(oUpdatedValues).length) {
        oResponse = await update(oCancellation.id, oUpdatedValues);
      }
    } else {
      oResponse = await save(oSend);
    }

    if (oResponse?.ok) {
      message.success(
        oCancellation.id ? 'Registro actualizado!' : 'Registro creado'
      );
      onEnd();
    } else {
      const { data } = oResponse || {};
      blnValid = false;
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map(oError => {
            let aKeyName = oError.path.split('.');
            if (aKeyName.length > 1) {
              let oPrev = {
                ...aTempValidations[aKeyName[0]],
              };
              oPrev[aKeyName[1]] = {
                help:
                  oError.type === 'unique violation'
                    ? 'No disponible! Intente con otro valor'
                    : 'Error',
                status: 'error',
              };
              aTempValidations[aKeyName[0]] = oPrev;
            } else {
              aTempValidations[aKeyName[0]] = {
                help:
                  oError.type === 'unique violation'
                    ? 'El registro ya existe'
                    : 'Error',
                status: 'error',
              };
            }
          });
        } else {
          for (let sKey in data.errors) {
            aTempValidations[sKey.replace(',', '_')] = {
              help: data.errors[sKey],
              status: 'error',
            };
          }
        }
      }
    }

    if (!blnValid) {
      setValidations(aTempValidations);
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={onSubmit}
      title={title}
      visible={visible}
      width={800}
    >
      <Form layout="vertical" name="cancellation-form">
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              help={validations.code?.help}
              label="Código"
              validateStatus={validations.code?.status}
            >
              <Input
                name="code"
                onChange={getFormData}
                placeholder="Ingresa el código"
                value={cancellation?.code}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              help={validations.description?.help}
              label="Descripción"
              validateStatus={validations.description?.status}
            >
              <Input
                name="description"
                onChange={getFormData}
                placeholder="Ingresa la Descripción"
                value={cancellation?.description}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.status?.help}
              label="Estatus"
              validateStatus={validations.status?.status}
            >
              <Select
                name="status"
                onChange={value => getSelectValue('status', value)}
                value={cancellation ? cancellation.status : 1}
              >
                <Option value={1}>Activo</Option>
                <Option value={0}>Inactivo</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

CancellationForm.propTypes = {
  onCancel: PropTypes.func,
  onEnd: PropTypes.func,
  oInit: PropTypes.object,
  oCancellation: PropTypes.object,
  title: PropTypes.string,
  visible: PropTypes.bool,
};
