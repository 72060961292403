import React, { useState } from 'react';
import { Button, Col, Tooltip, Layout, Menu, Row } from 'antd';
import {
  RightOutlined,
  PoweroffOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth } from '../../hooks/Auth.hook';
import { MainMenu } from '..';

const { Sider } = Layout;
const logo = require('../../assets/images/logo.png').default;

export const LeftSider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [{ user }, , logout] = useAuth();

  const userMenu = (
    <Menu theme="dark" style={{ backgroundColor: '#87CA3E' }}>
      <Menu.Item key="menu-logoff" onClick={logout}>
        <PoweroffOutlined />
        <span style={{ marginLeft: 6 }}>Cerrar Sesión</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      width={300}
    >
      <Row className="logo" align="middle" justify="center">
        <Col>
          <img src={logo} alt="" />
        </Col>
        <Col className="title-logo">
          <h3>FacturaXion</h3>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Tooltip
            overlayClassName="custom-tooltip"
            title={userMenu}
            placement="right"
            color="#87CA3E"
          >
            <Button
              className="user-menu"
              type="link"
              icon={<UserOutlined color="#007C89" />}
            >
              <span className="user-name">{user.full_name}</span>
              <RightOutlined />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <MainMenu />
    </Sider>
  );
};
