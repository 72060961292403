import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Form, Input, message, Modal, Row } from 'antd';
import { useGetInputData } from '../../hooks/Common.hook';
import { fnResetValidations, skipSameValues } from '../../utils/functions';
import { useCompany } from '../../hooks/Company.hook';

export const CompanyForm = ({
  onCancel,
  onEnd,
  oInit,
  oCompany,
  title,
  visible,
}) => {
  const [company, setCompany] = useState(oInit);
  const [validations, setValidations] = useState([]);
  const [getFormData] = useGetInputData(company, setCompany);
  const resetValidations = () => fnResetValidations(company, setValidations);
  const [, loading, , , save, update] = useCompany();

  useEffect(() => {
    if (visible) {
      setCompany(oCompany);
    }
  }, [visible, setCompany, oCompany]);

  const onSubmit = async () => {
    let aTemp = resetValidations(),
      aTempValidations = [...aTemp],
      blnValid = true,
      oSend = { ...company },
      oResponse;

    for (const sKey in oSend) {
      if (!oSend[sKey] || oSend[sKey] === '') {
        delete oSend[sKey];
      }
    }

    if (oSend.id) {
      const oUpdatedValues = skipSameValues(oCompany, oSend);
      if (Object.keys(oUpdatedValues).length) {
        oResponse = await update(oCompany.id, oUpdatedValues);
      }
    } else {
      oResponse = await save(oSend);
    }

    if (oResponse?.ok) {
      message.success(
        oCompany?.id ? 'Compañía Actualizada' : 'Compañía creada'
      );
      onEnd();
    } else {
      const { data } = oResponse || {};
      blnValid = false;
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map(oError => {
            const aKeyName = oError.path.split('.');
            if (aKeyName.length > 1) {
              let oPrev = {
                ...aTempValidations[aKeyName[0]],
              };
              oPrev[aKeyName[1]] = {
                help:
                  oError.type === 'unique violation'
                    ? 'No disponible! Intente con otro valor'
                    : 'Error',
                status: 'error',
              };
              aTempValidations[aKeyName[0]] = oPrev;
            } else {
              aTempValidations[aKeyName[0]] = {
                help:
                  oError.type === 'unique violation'
                    ? 'La compañía ya existe'
                    : 'Error',
                status: 'error',
              };
            }
          });
        } else {
          for (const sKey in data.errors) {
            aTempValidations[sKey.replace(',', '_')] = {
              help: data.errors[sKey],
              status: 'error',
            };
          }
        }
      }
    }

    if (!blnValid) {
      setValidations(aTempValidations);
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={onSubmit}
      title={title}
      visible={visible}
      width={800}
    >
      <Form layout="vertical" name="company-form">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              help={validations.business_name?.help}
              label="Razón Social"
              validateStatus={validations.business_name?.status}
            >
              <Input
                name="business_name"
                onChange={getFormData}
                placeholder="Ingrese Razón Social"
                value={company?.business_name}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.rfc?.help}
              label="RFC"
              validateStatus={validations.rfc?.status}
            >
              <Input
                name="rfc"
                onChange={getFormData}
                placeholder="Ingrese RFC"
                value={company?.rfc}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              help={validations.short_name?.help}
              label="Nombre Corto"
              validateStatus={validations.short_name?.status}
            >
              <Input
                name="short_name"
                onChange={getFormData}
                placeholder="Ingrese Nombre corto"
                value={company?.short_name}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" plain>
          Contacto
        </Divider>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              help={validations.contact_name?.help}
              label="Nombre"
              validateStatus={validations.contact_name?.status}
            >
              <Input
                name="contact_name"
                onChange={getFormData}
                placeholder="Ingrese nombre"
                value={company?.contact_name}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.contact_phone?.help}
              label="Teléfono"
              validateStatus={validations.contact_phone?.status}
            >
              <Input
                name="contact_phone"
                onChange={getFormData}
                placeholder="Ingrese teléfono"
                value={company?.contact_phone}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.contact_email?.help}
              label="Email"
              validateStatus={validations.contact_email?.status}
            >
              <Input
                name="contact_email"
                onChange={getFormData}
                placeholder="Ingrese email"
                value={company?.contact_email}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" plain>
          Dirección
        </Divider>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              help={validations.address?.help}
              label="Calle"
              validateStatus={validations.address?.status}
            >
              <Input
                name="address"
                onChange={getFormData}
                placeholder="Ingrese Dirección"
                value={company?.address}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.ext_no?.help}
              label="Número Exterior"
              validateStatus={validations.ext_no?.status}
            >
              <Input
                name="ext_no"
                onChange={getFormData}
                placeholder="Ingrese no. exterior"
                value={company?.ext_no}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.int_no?.help}
              label="Número Interior"
              validateStatus={validations.int_no?.status}
            >
              <Input
                name="int_no"
                onChange={getFormData}
                placeholder="Ingrese no. interior"
                value={company?.int_no}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              help={validations.city?.help}
              label="Ciudad"
              validateStatus={validations.city?.status}
            >
              <Input
                name="city"
                onChange={getFormData}
                placeholder="Ingrese la ciudad"
                value={company?.city}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.state?.help}
              label="Estado"
              validateStatus={validations?.state?.status}
            >
              <Input
                name="state"
                onChange={getFormData}
                placeholder="Ingrese el estado"
                value={company?.state}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations?.country?.help}
              label="País"
              validateStatus={validations?.country?.status}
            >
              <Input
                name="country"
                onChange={getFormData}
                placeholder="Ingrese el país"
                value={company?.country}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

CompanyForm.propTypes = {
  onCancel: PropTypes.func,
  onEnd: PropTypes.func,
  oInit: PropTypes.object,
  oCompany: PropTypes.object,
  title: PropTypes.string,
  visible: PropTypes.bool,
};
