import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { FXLayout, SearchBar, Table, UserForm } from '../../components';

import { aColumns } from './columns';
import { aSearchElements, oInitialSearch, oInitialState } from './constants';
import { generateQueries } from '../../utils/functions';
import { useUser } from '../../hooks/User.hook';

export const UsersScreen = () => {
  const [user, setUser] = useState(oInitialState);
  const [modalVisible, setVisible] = useState(false);
  const [search, setSearch] = useState(oInitialSearch);
  const [oUsers, loading, query] = useUser();

  const handleEdit = sId => {
    const userEdit = oUsers?.data.find(oItem => sId === oItem.id);
    if (userEdit) {
      setUser(userEdit);
      setVisible(true);
    }
  };

  const handleEnd = () => {
    setVisible(false);
    onSearch();
  };

  const handleNew = () => {
    setUser(oInitialState);
    setVisible(true);
  };

  const onPageChange = (nPage, nSize) => {
    if (!loading) {
      const nSkip = nPage * nSize - nSize;
      query(generateQueries(search, aSearchElements), nSkip, nSize);
    }
  };

  const onReset = () => {
    setSearch(oInitialSearch);
    query(generateQueries(oInitialSearch, aSearchElements));
  };

  const onSearch = () => query(generateQueries(search, aSearchElements));

  return (
    <FXLayout>
      <UserForm
        oInit={oInitialState}
        onCancel={() => setVisible(!modalVisible)}
        onEnd={handleEnd}
        oUser={user}
        title={user?.id ? 'Editar Usuario' : 'Nuevo Usuario'}
        visible={modalVisible}
      />
      <Row gutter={[20, 20]} justify="space-between">
        <Col>
          <h3>Lista de usuarios</h3>
        </Col>
        <Col style={{ textAlign: 'end' }}>
          <Button onClick={handleNew} type="primary">
            Nuevo
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={aSearchElements}
        handleReset={onReset}
        handleSearch={onSearch}
        search={search}
        setSearch={setSearch}
      />
      <Table
        columns={aColumns(handleEdit)}
        data={oUsers?.data || []}
        loading={loading}
        pagination={{
          current: oUsers.skip / oUsers.limit + 1,
          locale: {
            items_per_page: 'p/página',
          },
          onChange: onPageChange,
          pageSize: oUsers.limit,
          pageSizeOptions: [10, 20, 50],
          total: oUsers.total,
          showSizeChanger: oUsers.total > 10,
        }}
        rowKey="id"
      />
    </FXLayout>
  );
};
