import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FIND, process, SAVE, UPDATE } from '../services/Api';

export const useCompany = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit, queries, skip });

  const getCompanies = useCallback(async () => {
    setLoading(true);
    const oResponse = await process(FIND, 'companies', {}, params);
    setLoading(false);
    if (oResponse.ok) {
      setData(oResponse.data);
    } else {
      message.error('Error al consultar empresas');
    }
  }, [process, setLoading, params]);

  const postCompany = useCallback(
    async payload => {
      setLoading(true);
      const oResponse = await process(SAVE, 'companies', payload);
      setLoading(false);

      return oResponse;
    },
    [process, setLoading]
  );

  const patchCompany = useCallback(
    async (sId, payload) => {
      setLoading(true);
      const oResponse = await process(UPDATE, 'companies', payload, {
        id: sId,
      });
      setLoading(false);

      return oResponse;
    },
    [process, setLoading]
  );

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const onChangeParams = (q = '', s = 0, l = 10) => {
    setParams({ limit: l, queries: q, skip: s });
  };

  const update = () => getCompanies();

  return [
    { ...params, ...data },
    loading,
    onChangeParams,
    update,
    postCompany,
    patchCompany,
  ];
};

export const useUserCompany = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit, queries, skip });

  const getCompanyUsers = useCallback(async () => {
    setLoading(true);
    const oResponse = await process(FIND, 'users-companies', {}, params);
    setLoading(false);

    if (oResponse.ok) {
      setData(oResponse.data);
    } else {
      message.error('Error al obtener compañias por usuario');
    }
  }, [process, setLoading, params]);

  useEffect(() => {
    getCompanyUsers();
  }, [getCompanyUsers]);

  const postAssign = useCallback(
    async payload => {
      setLoading(true);
      const oResponse = await process(SAVE, 'users-companies', payload);
      setLoading(false);

      return oResponse;
    },
    [process]
  );

  const onChangeParams = (q = '', s = 0, l = 10) => {
    setParams({ limit: l, queries: q, skip: s });
  };

  const update = () => getCompanyUsers();

  return [{ ...params, ...data }, loading, onChangeParams, update, postAssign];
};
