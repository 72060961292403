import React, { useCallback, useState } from 'react';
import { Col, Row, Button } from 'antd';

import { FXLayout, SearchBar, Table, UploadFiles } from '../../components';
import { aColumns } from './columns';
import { aSearchElements, oInitialSearch } from './constants';
import { useAuth, useBillStatus, useCompany, useSource } from '../../hooks';
import { generateQueries, generateSorts } from '../../utils/functions';

export const SourcesScreen = () => {
  const [search, setSearch] = useState(oInitialSearch);
  const [visible, setVisible] = useState(false);
  const [sortOpts, setSortOpts] = useState('$sort[date]=-1');
  const [oSource, loading, query] = useSource();
  const [oCompany] = useCompany();
  const [aStatus] = useBillStatus();
  const [{ token }] = useAuth();
  const searchElements = aSearchElements(oCompany.data || [], aStatus || []);

  const handleSearch = useCallback(() => {
    query(generateQueries(search, searchElements));
  });

  const onPageChange = (nPage, nSize) => {
    if (!loading) {
      const nSkip = nPage * nSize - nSize;
      query(generateQueries(search, searchElements), sortOpts, nSkip, nSize);
    }
  };

  const onHandleChange = (pagination, _, sorter) => {
    const sSort = generateSorts(Array.isArray(sorter) ? sorter : [sorter]),
      nSkip = pagination.current * pagination.pageSize - pagination.pageSize;

    setSortOpts(sSort);
    query(
      generateQueries(search, searchElements),
      sSort,
      nSkip,
      pagination.pageSize
    );
  };

  const onReset = () => {
    setSearch(oInitialSearch);
    query(generateQueries(oInitialSearch, searchElements));
  };

  return (
    <FXLayout>
      <UploadFiles onCancel={() => setVisible(!visible)} visible={visible} />
      <Row gutter={[20, 20]}>
        <Col flex="1">
          <h3>Lista de Insumos</h3>
        </Col>
        <Col flex="0">
          <Button type="primary" onClick={() => setVisible(true)}>
            Subir
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={searchElements}
        handleReset={onReset}
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
      />
      <Row>
        <Col span={24}>
          <Table
            columns={aColumns(token)}
            data={oSource.data}
            loading={loading}
            pagination={{
              current: oSource.skip / oSource.limit + 1,
              locale: {
                items_per_page: 'p/página',
              },
              onChange: onPageChange,
              pageSize: oSource.limit,
              pageSizeOptions: [10, 20, 50],
              total: oSource.total,
              showSizeChanger: oSource.total > 10,
            }}
            onChange={onHandleChange}
            rowKey="id"
          />
        </Col>
      </Row>
    </FXLayout>
  );
};
