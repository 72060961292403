import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { LeftSider } from '..';

const { Content } = Layout;

export const FXLayout = ({ children }) => {
  useEffect(() => {
    document.title = 'FacturaXion';
  }, [children]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <LeftSider />
      <Layout className="site-layout">
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

FXLayout.propTypes = {
  children: PropTypes.array.isRequired,
};
