import React from 'react';
import PropTypes from 'prop-types';
import { Row, Table as ATable } from 'antd';

export const Table = ({
  columns,
  data,
  ids,
  loading = false,
  rowKey,
  setIds,
  size = 'small',
  ...rest
}) => {
  const rowSelection = {
    onChange: selectedKeys => setIds(selectedKeys),
    selectedRowKeys: ids,
  };

  return (
    <Row>
      <ATable
        {...{ columns, loading, size }}
        dataSource={data}
        rowKey={row => (rowKey ? row[rowKey] : row.id)}
        rowSelection={ids && setIds ? rowSelection : undefined}
        style={{ width: '100%' }}
        {...rest}
      />
    </Row>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  ids: PropTypes.array,
  loading: PropTypes.bool,
  rowKey: PropTypes.string,
  setIds: PropTypes.func,
  size: PropTypes.string,
};
