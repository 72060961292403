import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import moment from 'moment';

import { FXLayout, SearchBar } from '../../components';
import { aSearchElements, oInitialSearch } from './constants';
import { useCompany, useDashboard } from '../../hooks';
import { LineChart } from '../../components/molecule/LineChart';
import { Line2Chart } from '../../components/molecule/Line2Chart';
import { PieChart } from '../../components/molecule/PieChart';

export const HomeScreen = () => {
  const [search, setSearch] = useState(oInitialSearch);
  const [oCompany] = useCompany();
  const [oData, loading, query] = useDashboard(oInitialSearch);
  const [aCards, setCards] = useState(null);

  useEffect(() => {
    let aTemp = oData?.cards ? [...oData.cards] : [];
    if (aTemp.length) {
      setCards(aTemp);
    }
  }, [oData?.cards, setCards]);

  const onReset = () => ({});

  const onSearch = () => {
    query(search);
  };

  const customFormatter = value => {
    let oDate = moment(value, 'YYYY-MM-DD'),
      sMonth = oDate.format('MMM');

    sMonth = sMonth.replace('.', '');
    sMonth = sMonth[0].toUpperCase() + sMonth.substr(1, 2);

    return `${oDate.format('DD')}/${sMonth}/${oDate.format('YYYY')}`;
  };

  const customTotalFormat = (value, isPrice = false) => {
    let sFormat = parseFloat(value);
    if (isPrice) {
      sFormat = sFormat.toFixed(2);
    }
    sFormat = String(sFormat).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    return `${isPrice ? '$' : ''}${sFormat}`;
  };

  return (
    <FXLayout>
      <SearchBar
        elements={aSearchElements(oCompany.data || [])}
        handleReset={onReset}
        handleSearch={onSearch}
        search={search}
        setSearch={setSearch}
      />
      <Row style={{ marginBottom: 30 }}>
        <Col span={24}>
          <Card
            loading={loading}
            title="Total de Facturas por Tipo de Comprobante"
          >
            <PieChart data={aCards} />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col span={24}>
          <Card loading={loading} title="Cantidad de Facturas por Día">
            <LineChart
              aData={oData?.graphs.amount.data || []}
              customFormatter={customFormatter}
              customTotalFormat={customTotalFormat}
            />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col span={24}>
          <Card loading={loading} title="$ Monto Total por Día">
            <Line2Chart
              aData={oData?.graphs.total.data}
              customFormatter={customFormatter}
              customTotalFormat={customTotalFormat}
            />
          </Card>
        </Col>
      </Row>
    </FXLayout>
  );
};
