import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, message, Modal, Row, Select } from 'antd';
import { useGetInputData } from '../../hooks/Common.hook';
import { fnResetValidations, skipSameValues } from '../../utils/functions';
import { useUser } from '../../hooks/User.hook';

const { Option } = Select;

export const UserForm = ({ onCancel, onEnd, oInit, oUser, title, visible }) => {
  const [user, setUser] = useState(oInit);
  const [validations, setValidations] = useState([]);
  const [getFormData, getSelectValue] = useGetInputData(user, setUser);
  const resetValidations = () => fnResetValidations(user, setValidations);
  const [, loading, , , save, update] = useUser();

  useEffect(() => {
    if (visible) {
      setUser(oUser);
    }
  }, [visible, setUser, oUser]);

  const onSubmit = async () => {
    let aTemp = resetValidations(),
      aTempValidations = [...aTemp],
      blnValid = true,
      oSend = { ...user },
      oResponse;

    if (oSend.id) {
      const oUpdatedValues = skipSameValues(oUser, oSend);
      if (Object.keys(oUpdatedValues).length) {
        oResponse = await update(oUser.id, oUpdatedValues);
      }
    } else {
      oResponse = await save(oSend);
    }

    if (oResponse?.ok) {
      message.success(oUser.id ? 'Usuario actualizado!' : 'Usuario creado!');
      onEnd();
    } else {
      const { data } = oResponse || {};
      blnValid = false;
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map(oError => {
            aTempValidations[oError.path] = {
              help:
                oError.type === 'unique violation'
                  ? 'El Usuario ya existe en nuestros registros'
                  : 'Error',
              status: 'error',
            };
          });
        } else {
          for (let sKey in data.errors) {
            aTempValidations[sKey] = {
              help: data.errors[sKey],
              status: 'error',
            };
          }
        }
      }

      if (data?.data) {
        if (data.data?.label === 'API_USER_PASS_NOT_MATCH') {
          const oNotMatch = {
            help: 'Contraseñas diferentes',
            status: 'error',
          };
          aTempValidations['password'] = aTempValidations['rpassword'] =
            oNotMatch;
        }
      }
    }

    if (!blnValid) {
      setValidations(aTempValidations);
    }
  };

  return (
    <Modal
      centered
      loading={loading}
      onCancel={onCancel}
      onOk={onSubmit}
      title={title}
      visible={visible}
      width={800}
    >
      <Form layout="vertical" name="user-form">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              help={validations.email?.help}
              label="Email"
              validateStatus={validations.email?.status}
            >
              <Input
                name="email"
                onChange={getFormData}
                placeholder="Ingrese email"
                value={user?.email}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              help={validations.username?.help}
              label="Usuario"
              validateStatus={validations.username?.status}
            >
              <Input
                name="username"
                onChange={getFormData}
                placeholder="Ingrese nombre de usuario"
                value={user?.username}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              help={validations.full_name?.help}
              label="Nombre"
              validateStatus={validations.full_name?.status}
            >
              <Input
                name="full_name"
                onChange={getFormData}
                placeholder="Ingrese nombre completo"
                value={user?.full_name}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              help={validations.rol_id?.help}
              label="Rol"
              validateStatus={validations.rol_id?.status}
            >
              <Select
                name="rol_id"
                onChange={value => getSelectValue('rol_id', value)}
                value={user ? user.rol_id : 3}
              >
                <Option value={1}>Administradores</Option>
                <Option value={2}>Web API</Option>
                <Option value={3}>Clientes</Option>
                <Option value={5}>Auxiliar</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.password?.help}
              label="Contraseña"
              validateStatus={validations.password?.status}
            >
              <Input
                name="password"
                onChange={getFormData}
                placeholder="Ingrese contraseña"
                type="password"
                value={user?.password}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.rpassword?.help}
              label="Confirmar contraseña"
              validateStatus={validations.rpassword?.status}
            >
              <Input
                name="rpassword"
                onChange={getFormData}
                placeholder="Repita contraseña"
                type="password"
                value={user?.rpassword}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              help={validations.status?.help}
              label="Estatus"
              validateStatus={validations.status?.status}
            >
              <Select
                name="status"
                onChange={value => getSelectValue('status', value)}
                value={user ? user.status : 1}
              >
                <Option value={0}>Inactivo</Option>
                <Option value={1}>Activo</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

UserForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  oInit: PropTypes.object.isRequired,
  oUser: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};
