import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { Alert, Col, Layout, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useVerify } from '../../hooks/Auth.hook';
const { Content } = Layout;
const logo = require('../../assets/images/logo.png').default;

export const VerifyScreen = () => {
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [verify] = useVerify();
  const { token } = useParams();

  const sendToken = useCallback(async data => {
    const response = await verify(data);

    setLoading(false);
    if (response.ok) {
      setMessage(
        'El correo electrónico ha sido verificado correctamente, su usuario ha sido activado, ahora puede iniciar sesión.'
      );
    } else {
      setError(response.data?.message || '');
    }
  }, []);

  useEffect(() => {
    if (token) {
      sendToken(token);
    }
  }, [token]);

  return (
    <Layout>
      <Content className="login-screen">
        <Row style={{ height: '100vh' }}>
          <Col className="login-form" span={12}>
            <div className="spacer">
              <Row className="logo" align="middle" justify="center">
                <Col>
                  <img src={logo} alt="" />
                </Col>
                <Col>
                  <h3>FacturaXion</h3>
                </Col>
              </Row>
              {token ? (
                <>
                  <h4>Verificación de correo</h4>
                  {loading ? (
                    <>
                      <Alert
                        message="Verificando..."
                        type="info"
                        showIcon={true}
                        icon={<LoadingOutlined />}
                      />
                    </>
                  ) : error ? (
                    <Alert message={error} type="error" />
                  ) : (
                    <Alert message={message} type="success" />
                  )}
                </>
              ) : (
                <>
                  <h4>Verificación de correo</h4>
                  <Alert
                    message="El token de verificación de correo electrónico no es válido!"
                    type="error"
                  />
                </>
              )}
              <span style={{ marginTop: 10 }} className="recover">
                Inicia sesión&nbsp;<a href="/">aquí</a>
              </span>
            </div>
          </Col>
          <Col span={12}>
            <div className="wrapper">
              <div />
              <div />
              <div />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
