import React, { useState, useEffect } from 'react';
import ProtoTypes from 'prop-types';
import { Alert, Modal, Descriptions, Button } from 'antd';

import { normalDate } from '../../utils/dates';

let description, substitution;
export const CancelInfo = ({ types = [], row, onAfterClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (row !== undefined) {
      setVisible(true);
    }
  }, [row]);

  const getDescription = data => {
    if (types.length && data?.cancel_code) {
      let result = types.find(item => item.code === data.cancel_code);

      return result ? result.description : false;
    }

    return false;
  };

  const getSubstitution = data => {
    if (data?.cancel_code === '01' && data?.cancel_substitution !== undefined) {
      return data?.cancel_substitution;
    }

    return false;
  };

  return (
    <Modal
      centered
      visible={visible}
      afterClose={onAfterClose}
      title="Información de la Cancelación"
      footer={
        <>
          <Button onClick={() => setVisible(false)}>Cerrar</Button>
        </>
      }
    >
      {row ? (
        <Descriptions column={1} labelStyle={{ fontWeight: 'bold' }}>
          {row?.user?.full_name && (
            <Descriptions.Item label="Usuario">
              {row?.user?.full_name}
            </Descriptions.Item>
          )}
          {row?.cancel_date && (
            <Descriptions.Item label="Fecha">
              {normalDate(row?.cancel_date)}
            </Descriptions.Item>
          )}
          {(description = getDescription(row)) && (
            <Descriptions.Item label="Motivo">{description}</Descriptions.Item>
          )}
          {(substitution = getSubstitution(row)) && (
            <Descriptions.Item label="UUID de sustitución">
              {substitution}
            </Descriptions.Item>
          )}
        </Descriptions>
      ) : (
        <Alert message="Favor de seleccionar un registro" type="warning" />
      )}
    </Modal>
  );
};

CancelInfo.propTypes = {
  onAfterClose: ProtoTypes.func.isRequired,
  types: ProtoTypes.array,
  row: ProtoTypes.any,
};
