import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { FIND, process, SAVE, UPDATE } from '../services/Api';

export const useClient = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoding] = useState(false);
  const [params, setParams] = useState({ limit, queries, skip });

  const getClients = useCallback(async () => {
    setLoding(true);
    const oResponse = await process(FIND, 'clients', {}, params);

    setLoding(false);
    if (oResponse.ok) {
      setData(oResponse.data);
    } else {
      message.error('Error al cargar clientes');
    }
  }, [process, params]);

  const postClient = useCallback(
    async payload => {
      setLoding(true);
      const oResponse = await process(SAVE, 'clients', payload);
      setLoding(false);

      return oResponse;
    },
    [process, setLoding]
  );

  const patchClient = useCallback(
    async (sId, payload) => {
      setLoding(true);
      const oResponse = await process(UPDATE, 'clients', payload, { id: sId });
      setLoding(false);

      return oResponse;
    },
    [process, setLoding]
  );

  useEffect(() => {
    getClients();
  }, [getClients]);

  const onChangeParams = (q = '', s = 0, l = 10) => {
    setParams({ limit: l, queries: q, skip: s });
  };

  const update = () => getClients();

  return [
    { ...params, ...data },
    loading,
    onChangeParams,
    update,
    postClient,
    patchClient,
  ];
};
