import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';

import { process, FIND, SAVE, UPDATE } from '../services/Api';

export const useUser = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit, queries, skip });

  const getUsers = useCallback(async () => {
    setLoading(true);
    const oResponse = await process(FIND, 'users', {}, params);

    setLoading(false);
    if (oResponse.ok) {
      setData(oResponse.data);
    } else {
      message.error('Error al cargar usuarios');
    }
  }, [process, params]);

  const postUser = useCallback(
    async payload => {
      setLoading(true);
      const oResponse = await process(SAVE, 'users', payload);

      setLoading(false);

      return oResponse;
    },
    [process]
  );

  const patchUser = useCallback(
    async (sId, payload) => {
      setLoading(true);
      const oResponse = await process(UPDATE, 'users', payload, { id: sId });
      setLoading(false);

      return oResponse;
    },
    [process, setLoading]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onChangeParams = (q = '', s = 0, l = 10) => {
    setParams({ limit: l, queries: q, skip: s });
  };

  const update = () => getUsers();

  return [
    { ...params, ...data },
    loading,
    onChangeParams,
    update,
    postUser,
    patchUser,
  ];
};
