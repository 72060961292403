import React, { useCallback, useState } from 'react';
import { Button, Col, message, Row } from 'antd';

import {
  CompanyForm,
  FXLayout,
  SearchBar,
  SettingsForm,
  Table,
} from '../../components';
import { aColumns } from './columns';
import { aSearchElements, oInitialSearch, oInitialState } from './constants';
import { generateQueries } from '../../utils/functions';
import { useCompany } from '../../hooks';
import { process, UPDATE } from '../../services/Api';

export const CompaniesScreen = () => {
  const [search, setSearch] = useState(oInitialSearch);
  const [company, setCompany] = useState(null);
  const [visible, setVisible] = useState(false);
  const [confVisible, setConfVisible] = useState(false);
  const searchElements = aSearchElements([]);
  const [oCompanies, loading, query] = useCompany();

  const handleConf = sId => {
    const companyEdit = oCompanies?.data.find(oItem => oItem.id === sId);
    if (companyEdit) {
      setCompany(companyEdit);
      setConfVisible(true);
    }
  };

  const handleDelete = sId => {
    const oResponse = process(UPDATE, 'companies', { status: 0 }, { id: sId });
    if (oResponse.ok) {
      message.success('Compañía desactivada correctamente');
    }
  };

  const handleEdit = sId => {
    const companyEdit = oCompanies?.data.find(oItem => oItem.id === sId);
    if (companyEdit) {
      setCompany(companyEdit);
      setVisible(true);
    }
  };

  const handleEnd = () => {
    setVisible(false);
    onSearch();
  };

  const handleNew = () => {
    setCompany(oInitialState);
    setVisible(true);
  };

  const handleSearch = useCallback(() => {
    query(generateQueries(search, searchElements));
  }, [query]);

  const onPageChange = (nPage, nSize) => {
    if (!loading) {
      const nSkip = nPage * nSize - nSize;
      query(generateQueries(search, searchElements), nSkip);
    }
  };

  const onReset = () => {
    setSearch(oInitialSearch);
    query(generateQueries(oInitialSearch, searchElements));
  };

  const onSearch = () => query(generateQueries(search, searchElements));

  const onUpdateConf = async oConf => {
    const oResponse = await process(
      UPDATE,
      'companies',
      { configuration: oConf },
      { id: company.id }
    );
    if (oResponse?.ok) {
      message.success('Configuración guardada');
      onSearch();
      setConfVisible(false);
    } else {
      message.error('Error al guardar configuración');
    }
  };

  return (
    <FXLayout>
      <CompanyForm
        oCompany={company}
        oInit={oInitialState}
        onCancel={() => setVisible(!visible)}
        onEnd={handleEnd}
        title={company?.id ? 'Editar Compañía' : 'Nueva Compañía'}
        visible={visible}
      />
      <SettingsForm
        company={company}
        onCancel={() => setConfVisible(!confVisible)}
        onSubmit={onUpdateConf}
        visible={confVisible}
      />
      <Row align="middle" gutter={[20, 20]} justify="space-between">
        <Col flex="auto">
          <h3>Lista de Compañías</h3>
        </Col>
        <Col style={{ textAlign: 'end' }}>
          <Button onClick={handleNew} type="primary">
            Nueva
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={searchElements}
        handleReset={onReset}
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
      />
      <Table
        columns={aColumns(handleDelete, handleEdit, handleConf)}
        data={oCompanies.data || []}
        loading={loading}
        pagination={{
          current: oCompanies.skip / oCompanies.limit + 1,
          locale: {
            items_per_page: 'p/página',
          },
          onChange: onPageChange,
          pageSize: oCompanies.limit,
          pageSizeOptions: [10, 20, 50],
          total: oCompanies.total,
          showSizeChanger: oCompanies.total > 10,
        }}
        rowKey="id"
      />
    </FXLayout>
  );
};
