/* eslint-disable indent */
import React from 'react';
import { Button, Tooltip } from 'antd';
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  FieldTimeOutlined,
  SendOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { getServer } from '../../utils/url';
import { normalDate } from '../../utils/dates';

export const aColumns = (onCancel, onSend, onInfo, sRol, token) => [
  {
    dataIndex: 'date',
    key: 'date',
    title: 'Fecha',
    render: normalDate,
    defaultSortOrder: 'descend',
    sorter: true,
  },
  {
    sorter: true,
    key: 'serie,folio',
    title: 'Serie/Folio',
    render: (_, row) => `${row.serie}/${row.folio}`,
  },
  {
    dataIndex: 'no_employee',
    key: 'no_employee',
    title: 'No. Empleado',
  },
  {
    dataIndex: 'rfc',
    key: 'rfc',
    title: 'RFC',
    // eslint-disable-next-line react/display-name
    render: (_, row) => (
      <>
        <strong>{row.rfc}</strong>
        <br />
        <Tooltip
          className="custom-rfc"
          placement="bottom"
          title={`${row.business_name}`}
        >
          {`${row.business_name}`}
        </Tooltip>
      </>
    ),
  },
  {
    // eslint-disable-next-line react/display-name
    render: (_, row) => (
      <div>
        <a
          download
          href={`${getServer()}/download?token=${token}&xml=${row.xml_file}`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <span className="table-bag info">XML</span>
        </a>
        <a
          download
          href={`${getServer()}/download?token=${token}&pdf=${row.pdf_file}`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <span className="table-bag danger">PDF</span>
        </a>
      </div>
    ),
    title: 'Descargas',
    width: 145,
  },
  {
    dataIndex: 'uuid',
    ellipsis: {
      showTitle: false,
    },
    key: 'uuid',
    title: 'UUID',
    // eslint-disable-next-line react/display-name
    render: uuid => (
      <Tooltip placement="bottom" title={uuid}>
        {uuid}
      </Tooltip>
    ),
  },
  {
    align: 'center',
    dataIndex: 'status_id',
    key: 'status_id',
    title: 'Estatus',
    // eslint-disable-next-line react/display-name
    render: (text, row) =>
      text === '1' ? (
        <span className="lbl-valid">Vigente</span>
      ) : text === '2' ? (
        <div onClick={() => onInfo(row)} className="hover-under lbl-cancel">
          <InfoCircleOutlined />
          <span> Cancelado</span>
        </div>
      ) : text === '3' ? (
        <div onClick={() => onInfo(row)} className="hover-under lbl-for-cancel">
          <FieldTimeOutlined />
          <span>Por Cancelar</span>
        </div>
      ) : (
        <div className="hover-under lbl-error-cancel">
          <WarningOutlined />
          <span> Error al Cancelar</span>
        </div>
      ),
  },
  {
    align: 'center',
    key: 'id',
    // eslint-disable-next-line react/display-name
    render: (_, oRow) => (
      <>
        {oRow.status_id === '1' &&
          ['consult', 'assistant'].indexOf(sRol) === -1 && (
            <Button onClick={() => onCancel(oRow)} style={{ marginRight: 5 }}>
              <CloseCircleOutlined />
            </Button>
          )}
        <Button onClick={() => onSend(oRow)}>
          <SendOutlined />
        </Button>
      </>
    ),
    title: 'Acciones',
  },
];
