import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Alert, Button, Col, Form, Input, Layout, Row, Spin } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useForgot } from '../../hooks/Auth.hook';

const { Content } = Layout;
const logo = require('../../assets/images/logo.png').default;

export const ForgotScreen = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [rPassword, setRPassword] = useState('');
  const [error] = useState('');
  const [{ loading }, forgot, patch] = useForgot();
  const { token } = useParams();

  const onLogin = async () => {
    if (user !== '') {
      await forgot(user);
    }
  };

  const onReset = async () => {
    if (password !== '' && rPassword !== '') {
      await patch(token, {
        password,
        rpassword: rPassword,
      });
    }
  };

  return (
    <Layout>
      <Content className="login-screen">
        {error && <Alert message={error} type="error" />}
        <Row style={{ height: '100vh' }}>
          <Col className="login-form" span={12}>
            <div className="spacer">
              <Row className="logo" align="middle" justify="center">
                <Col>
                  <img src={logo} alt="" />
                </Col>
                <Col>
                  <h3>FacturaXion</h3>
                </Col>
              </Row>
              {token ? (
                <>
                  <h4>Ingrese su nueva contraseña</h4>
                  <Form onFinish={onReset}>
                    <Form.Item
                      onChange={({ target }) => setPassword(target.value)}
                      name="password"
                      rules={[
                        { required: true, message: 'Ingrese contraseña' },
                      ]}
                    >
                      <Input.Password
                        placeholder="Contraseña"
                        prefix={<LockOutlined />}
                      />
                    </Form.Item>
                    <Form.Item
                      onChange={({ target }) => setRPassword(target.value)}
                      name="rpassword"
                      rules={[
                        { required: true, message: 'Ingrese contraseña' },
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirme contraseña"
                        prefix={<LockOutlined />}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" type="primary" block>
                        {loading ? <Spin /> : 'Recuperar'}
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              ) : (
                <>
                  <h4>
                    Ingresa tu email y te enviaremos un link para restablecer tu
                    contraseña.
                  </h4>
                  <Form onFinish={onLogin}>
                    <Form.Item
                      onChange={({ target }) => setUser(target.value)}
                      name="user"
                      rules={[{ required: true, message: 'Ingresa el email' }]}
                      value={user}
                    >
                      <Input
                        placeholder="Correo electrónico"
                        prefix={<MailOutlined />}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" type="primary" block>
                        {loading ? <Spin /> : 'Recuperar'}
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
              <span className="recover">
                Inicia sesión&nbsp;<a href="/">aquí</a>
              </span>
            </div>
          </Col>
          <Col span={12}>
            <div className="wrapper">
              <div />
              <div />
              <div />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
