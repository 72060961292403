import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const aSearchElements = [
  {
    label: 'Nombre',
    name: 'full_name',
    type: 'input',
  },
  {
    label: 'Email',
    name: 'email',
    type: 'input',
  },
  {
    label: 'Rol',
    name: 'rol_id',
    type: 'select',
    placeholder: 'Seleccione',
    // eslint-disable-next-line react/display-name
    values: () => (
      <>
        <Option value="1">Administradores</Option>
        <Option value="2">Web API</Option>
        <Option value="3">Clientes</Option>
        <Option value="5">Auxiliar</Option>
      </>
    ),
  },
];

export const oInitialSearch = {
  email: '',
  full_name: '',
  rol_id: undefined,
  status: undefined,
};

export const oInitialState = {
  email: '',
  full_name: '',
  id: undefined,
  password: '',
  rol_id: undefined,
  rpassword: '',
  status: undefined,
  username: '',
};
