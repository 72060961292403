import React from 'react';
import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';

export const Line2Chart = ({
  aData = [],
  customFormatter,
  customTotalFormat,
}) => {
  return (
    <Line
      data={aData}
      className="custom-graphs"
      xField="date"
      xAxis={{
        nice: true,
        label: {
          formatter: customFormatter,
          offset: 40,
          rotate: Math.PI / 2,
          style: {
            fontSize: 11,
          },
        },
        title: {
          text: 'Días',
          offset: 85,
        },
      }}
      yField="value"
      yAxis={{
        label: {
          formatter: value => customTotalFormat(value, true),
        },
        title: {
          text: 'Total ($)',
        },
      }}
      label={{
        position: 'middle',
        style: {
          fill: '#FFF',
          opacity: 0.6,
        },
      }}
      meta={{
        date: { alias: 'Fecha' },
        value: { alias: 'Cantidad' },
      }}
      lineStyle={{
        stroke: '#2ECC71',
      }}
      point={{
        size: 3,
        shape: 'diamond',
        style: {
          fill: '#FFF',
          stroke: '#2ECC71',
          lineWidth: 1,
        },
      }}
      tooltip={{
        customContent: (title, data) => {
          return [
            '<table class="custom-tooltip"><tbody>',
            `<tr><th>Día</th><td>${customFormatter(title)}</td></tr>`,
            `<tr><th>Total</th><td>${customTotalFormat(
              data.length ? data[0].value : '',
              true
            )}</td></tr>`,
            '</tbody></table>',
          ].join('');
        },
      }}
      renderer="svg"
    />
  );
};

Line2Chart.propTypes = {
  customFormatter: PropTypes.func,
  customTotalFormat: PropTypes.func,
  aData: PropTypes.array,
};
