import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const aSearchElements = (aCompanies, aStatus) => [
  {
    label: 'Empresa',
    name: 'company_id',
    placeholder: 'Seleccione',
    type: 'select',
    values: () =>
      aCompanies.map(oCompany => (
        <Option key={oCompany.id} value={oCompany.id}>
          {oCompany.business_name}
        </Option>
      )),
    visible: aCompanies.length > 1,
  },
  {
    label: 'Nombre',
    name: 'name',
    type: 'input',
  },
  {
    label: 'Fechas',
    name: 'date',
    type: 'date',
  },
  {
    label: 'Estatus',
    placeholder: 'Seleccione',
    name: 'status_id',
    type: 'select',
    sizes: { normal: 4 },
    values: () =>
      aStatus.map(
        oStatus =>
          oStatus.apply_for === 'sources' && (
            <Option key={oStatus.id} value={oStatus.id}>
              {oStatus.description}
            </Option>
          )
      ),
  },
  {
    label: 'Fecha Inicial',
    name: 'date_ini',
    type: 'date',
    visible: false,
  },
  {
    label: 'Fecha Final',
    name: 'date_end',
    type: 'date',
    visible: false,
  },
];

export const oInitialSearch = {
  company_id: undefined,
  date_ini: undefined,
  date_end: undefined,
  name: '',
  status_id: undefined,
};
