import React from 'react';
import { Select } from 'antd';
import moment from 'moment';

const { Option } = Select;

export const aSearchElements = aCompanies => [
  {
    label: 'Empresa',
    name: 'company_id',
    placeholder: 'Seleccione',
    type: 'select',
    values: () =>
      aCompanies.map(oCompany => (
        <Option key={oCompany.id} value={oCompany.id}>
          {oCompany.business_name}
        </Option>
      )),
    visible: aCompanies.length > 1,
  },
  {
    label: 'Fechas',
    name: 'date',
    type: 'date',
    sizes: {
      small: 10,
      normal: 8,
    },
  },
  {
    label: 'Fecha Inicial',
    name: 'date_ini',
    type: 'date',
    visible: false,
  },
  {
    label: 'Fecha Final',
    name: 'date_end',
    type: 'date',
    visible: false,
  },
];

export const oInitialSearch = {
  company_id: undefined,
  date_ini: moment().startOf('month'),
  date_end: moment().endOf('month'),
};
