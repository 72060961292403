import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export const ProtectedRoute = ({
  component: Component,
  loading = false,
  token,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (token && token !== '') {
          if (loading) {
            return <h1>Loading</h1>;
          }
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  key: 'home',
                },
              }}
            />
          );
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  token: PropTypes.string,
};
