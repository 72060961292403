import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { process, FIND } from '../services/Api';

export const useBillType = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit, queries, skip });
  const dispatch = useDispatch();

  const getTypes = useCallback(async () => {
    setLoading(true);
    const oResponse = await process(FIND, 'bill-types', {}, params);

    setLoading(false);
    if (oResponse.ok) {
      setData(oResponse.data?.data);
    } else {
      setError(oResponse.data);
    }
  }, [dispatch, process, params]);

  useEffect(() => {
    getTypes();
  }, [getTypes]);

  const onChangeParams = (q = '', s = 0, l = 10) => {
    setParams({ limit: l, queries: q, skip: s });
  };

  const update = () => getTypes();

  return [{ data, ...params }, loading, error, onChangeParams, update];
};
