export const useGetInputData = (state, setState) => {
  const getData = ({ target }) => {
    const { name, value } = target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const getSelectValue = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  return [getData, getSelectValue];
};
