import React, { useState } from 'react';
import { Button, Col, message, Row } from 'antd';
import { AssignForm, FXLayout, SearchBar, Table } from '../../components';

import { aColumns } from './columns';
import { aSearchElements, oInitialSearch, oInitialState } from './constants';
import { generateQueries } from '../../utils/functions';
import { useUserCompany } from '../../hooks/Company.hook';
import { DELETE, process } from '../../services/Api';

export const UserCompaniesScreen = () => {
  const [modalVisible, setVisible] = useState(false);
  const [search, setSearch] = useState(oInitialSearch);
  const [oData, loading, query] = useUserCompany();

  const handleDelete = async sId => {
    const oResponse = await process(DELETE, 'users-companies', {}, { id: sId });
    if (oResponse.ok) {
      message.success('Borrado correctamente');
      onSearch();
    } else {
      message.error('Error al quitar la asignación');
    }
  };

  const handleEnd = () => {
    setVisible(false);
    onSearch();
  };

  const handleNew = () => {
    setVisible(true);
  };

  const onPageChange = (nPage, nSize) => {
    if (!loading) {
      const nSkip = nPage * nSize - nSize;
      query(generateQueries(search, aSearchElements), nSkip, nSize);
    }
  };

  const onReset = () => {
    setSearch(oInitialSearch);
    query(generateQueries(oInitialSearch, aSearchElements));
  };

  const onSearch = () => query(generateQueries(search, aSearchElements));

  return (
    <FXLayout>
      <AssignForm
        oInit={oInitialState}
        onCancel={() => setVisible(!modalVisible)}
        onEnd={handleEnd}
        title="Nueva asignación"
        visible={modalVisible}
      />
      <Row gutter={[20, 20]} justify="space-between">
        <Col>
          <h2>Compañías por Usuario</h2>
        </Col>
        <Col style={{ textAlign: 'end' }}>
          <Button onClick={handleNew} type="primary">
            Asignar
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={aSearchElements}
        handleReset={onReset}
        handleSearch={onSearch}
        search={search}
        setSearch={setSearch}
      />
      <Table
        columns={aColumns(handleDelete)}
        data={oData?.data || []}
        loading={loading}
        pagination={{
          current: oData.skip / oData.limit + 1,
          locale: {
            items_per_page: 'p/página',
          },
          onChange: onPageChange,
          pageSize: oData.limit,
          pageSizeOptions: [10, 20, 50],
          total: oData.total,
          showSizeChanger: oData.total > 10,
        }}
        rowKey="id"
      />
    </FXLayout>
  );
};
