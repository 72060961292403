import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Select, Input } from 'antd';
const { Option } = Select;

const regUUID = new RegExp(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
);

export const CancelBillForm = ({
  types = [],
  loading,
  onCancel,
  onSubmit,
  visible,
}) => {
  const [code, setCode] = useState('01');
  const [description, setDescription] = useState('');
  const [showRepo, setShowRepo] = useState(true);
  const [formRef] = Form.useForm();

  useEffect(() => {
    setShowRepo(code === '01');
  }, [code]);

  useEffect(() => {
    if (!visible) {
      formRef.resetFields();
      setCode('01');
    }
  }, [visible]);

  const handleSubmit = () => {
    onSubmit({
      cancel_code: code,
      cancel_substitution: showRepo ? description : undefined,
      status_id: '3',
    });
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={() => formRef.submit()}
      title="Está seguro de cancelar esta factura?"
      visible={visible}
    >
      <Form
        layout="horizontal"
        form={formRef}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 14,
        }}
        initialValues={{
          code: '01',
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="code"
          label="Motivo"
          rules={[
            {
              required: true,
              message: 'Valor requerido!',
            },
          ]}
        >
          <Select placeholder="Seleccione" onChange={value => setCode(value)}>
            {types.map(oType => (
              <Option key={oType.id} value={oType.code}>
                {oType.code + ' - ' + oType.description}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {showRepo && (
          <Form.Item
            name="description"
            label="UUID de reposición"
            rules={[
              {
                required: true,
                message: 'Valor requerido!',
              },
              () => ({
                validator(_, value) {
                  if (value && regUUID.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('UUID no valido');
                },
              }),
            ]}
          >
            <Input onChange={value => setDescription(value.target.value)} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

CancelBillForm.propTypes = {
  types: PropTypes.array,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
