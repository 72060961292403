import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

import { persistor } from '../redux/store';
import { Auth } from '../redux/reducers/auth';
import {
  forgot as forgotAPI,
  login,
  patchPassword,
  createVerify,
} from '../services/Api';

export const useAuth = () => {
  const { auth } = useSelector(state => ({ auth: state.auth }));
  const dispatch = useDispatch();

  const post = async (email, password) => {
    dispatch(Auth.authRequest());
    const oResponse = await login(email, password);

    if (oResponse.ok) {
      const { accessToken, user } = oResponse.data;

      dispatch(Auth.authSuccess(accessToken, user));
    } else {
      if (oResponse.data?.message) {
        dispatch(Auth.authFailure(oResponse.data.message));
        message.error(oResponse.data.message);
      }
    }
  };

  const logout = () => {
    dispatch(Auth.authClear());
    persistor.flush();
    persistor.purge();
  };

  return [auth, post, logout];
};

export const useForgot = () => {
  const { auth } = useSelector(state => ({ auth: state.auth }));
  const dispatch = useDispatch();

  const forgot = async email => {
    dispatch(Auth.authRequest());
    const oResponse = await forgotAPI(email);
    if (oResponse.ok) {
      dispatch(Auth.authClear());
      message.success('Solicitud enviada, verifique su correo.');
    } else {
      if (oResponse.data?.message) {
        dispatch(Auth.authFailure(oResponse.data.message));
        message.error(oResponse.data.message);
      }
    }
  };

  const changePassword = async (token, payload) => {
    dispatch(Auth.authRequest());
    const oResponse = await patchPassword(token, payload);
    if (oResponse.ok) {
      dispatch(Auth.authClear());
      message.success('Contraseña actualizada correctamente');
    } else {
      if (oResponse.data?.message) {
        dispatch(Auth.authFailure(oResponse.data.message));
        message.error(oResponse.data.message);
      } else {
        dispatch('Error en la petición');
      }
    }
  };

  return [auth, forgot, changePassword];
};

export const useVerify = () => {
  const dispatch = useDispatch();

  const verify = async token => {
    dispatch(Auth.authRequest());
    const oResponse = await createVerify({ token });
    if (oResponse.ok) {
      dispatch(Auth.authClear());
    } else {
      if (oResponse.data?.message) {
        dispatch(Auth.authFailure(oResponse.data?.message));
        message.error(oResponse.data.message);
      }
    }

    return oResponse;
  };

  return [verify];
};
