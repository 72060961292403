import React from 'react';
import { Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const aColumns = (onDelete, onEdit) => [
  {
    dataIndex: 'code',
    key: 'code',
    title: 'Código',
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: 'Descripción',
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Estatus',
    render: nStatus => (nStatus === 1 ? 'Activo' : 'Inactivo'),
  },
  {
    // eslint-disable-next-line react/display-name
    render: (_, oRow) => (
      <>
        <Button onClick={() => onEdit(oRow.id)}>
          <EditOutlined />
        </Button>
        <Button onClick={() => onDelete(oRow.id)}>
          <DeleteOutlined />
        </Button>
      </>
    ),
    title: 'Acciones',
  },
];
