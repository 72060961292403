import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Switch, Input } from 'antd';

export const SettingsForm = ({
  company,
  loading,
  onCancel,
  onSubmit,
  visible,
}) => {
  const [payroll, setPayroll] = useState(false);
  const [pathSupp, setPathSuppliers] = useState('');

  useEffect(() => {
    if (visible && company?.configuration) {
      if (company.configuration.show_no_employee) {
        setPayroll(true);
      } else {
        setPayroll(false);
      }

      if (company.configuration.path_suppliers) {
        setPathSuppliers(company.configuration.path_suppliers);
      } else {
        setPathSuppliers('');
      }
    } else {
      setPayroll(false);
      setPathSuppliers('');
    }
  }, [visible, company]);

  const handleSubmit = () => {
    onSubmit({
      path_suppliers: pathSupp,
      show_no_employee: payroll,
    });
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={loading}
      onCancel={onCancel}
      onOk={handleSubmit}
      title="Configuraciones"
      visible={visible}
    >
      <Form
        layout="horizontal"
        initialValues={{
          path_suppliers: pathSupp,
          show_no_employee: payroll,
        }}
      >
        <Form.Item name="show_no_employee" label="Habilitar Nóminas">
          <Switch onChange={checked => setPayroll(checked)} checked={payroll} />
        </Form.Item>
        <Form.Item name="path_suppliers" label="Ruta de Insumos">
          <Input onChange={value => setPathSuppliers(value.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

SettingsForm.propTypes = {
  company: PropTypes.object,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
};
