import React from 'react';
import { Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const aColumns = (onDelete, onEdit) => [
  {
    dataIndex: 'rfc',
    key: 'rfc',
    title: 'RFC',
  },
  {
    dataIndex: 'business_name',
    key: 'business_name',
    title: 'Razón Social',
  },
  {
    dataIndex: 'address',
    key: 'address',
    title: 'Dirección',
    render: (_, row) => `${row.address} ${row.ext_no}`,
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'Ciudad',
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Estatus',
    render: nStatus => (nStatus === 1 ? 'Activo' : 'Inactivo'),
  },
  {
    // eslint-disable-next-line react/display-name
    render: (_, oRow) => (
      <>
        <Button onClick={() => onEdit(oRow.id)}>
          <EditOutlined />
        </Button>
        <Button onClick={() => onDelete(oRow.id)}>
          <DeleteOutlined />
        </Button>
      </>
    ),
    title: 'Acciones',
  },
];
