import immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const { Creators, Types } = createActions({
  billClear: [],
  billFailure: ['error'],
  billFetched: ['data'],
  billRequest: [],
});

const initialState = immutable({
  bills: undefined,
  error: false,
  errorMessage: null,
  loading: false,
});

function clear() {
  return initialState;
}

function failure(state, action) {
  let { error } = action;
  return state.merge({
    error: true,
    errorMessage: error,
    loading: false,
  });
}

function fetched(state, action) {
  let { data } = action;

  return state.merge({
    bills: data,
    error: false,
    errorMessage: null,
    loading: false,
  });
}

function request(state) {
  return state.merge({
    error: false,
    errorMessage: null,
    loading: true,
  });
}

const Handlers = {
  [Types.BILL_CLEAR]: clear,
  [Types.BILL_FAILURE]: failure,
  [Types.BILL_FETCHED]: fetched,
  [Types.BILL_REQUEST]: request,
};

export const Bill = Creators;
export const billTypes = Types;
export default createReducer(initialState, Handlers);
