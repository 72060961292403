import moment from 'moment';

export const formatDate = date => {
  let oDate = moment(date),
    sMonth = oDate.format('MMM');

  sMonth = sMonth.replace('.', '');
  sMonth = sMonth[0].toUpperCase() + sMonth.substr(1, 2);

  return oDate.format('DD/[' + sMonth + ']/YY HH:mm a');
};

export const normalDate = date => {
  let sDate = date.substr(0, date.indexOf('.')),
    aSplit = sDate.split('T'),
    oDate = moment(aSplit[0], 'YYYY-MM-DD'),
    aHours = aSplit[1].split(':');

  oDate.hour(parseInt(aHours[0]), 10);
  oDate.minute(parseInt(aHours[1], 10));
  oDate.second(parseInt(aHours[2], 10));

  return formatDate(oDate.toDate());
};
