import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const aSearchElements = (aCompanies, config) => [
  {
    label: 'Empresa',
    name: 'company_id',
    placeholder: 'Seleccione',
    type: 'select',
    values: () =>
      aCompanies.map(oCompany => (
        <Option key={oCompany.id} value={oCompany.id}>
          {oCompany.business_name}
        </Option>
      )),
    visible: aCompanies.length > 1,
  },
  {
    label: 'UUID',
    name: 'uuid',
    type: 'input',
  },
  {
    label: 'Serie',
    name: 'serie',
    type: 'input',
  },
  {
    label: 'Folio',
    name: 'folio',
    type: 'input',
  },
  {
    label: 'Tipo CFDI',
    name: 'bill_type_id',
    placeholder: 'Seleccione',
    type: 'select',
    visible: false,
  },
  {
    label: 'Estatus',
    name: 'status_id',
    placeholder: 'Seleccione',
    type: 'select',
    visible: false,
  },
  {
    label: 'RFC',
    name: 'rfc',
    type: 'input',
    visible: false,
  },
  {
    label: 'Razón Social',
    name: 'business_name',
    type: 'input',
    visible: false,
  },
  {
    label: 'No. Empleado',
    name: 'no_employee',
    type: 'input',
    visible: config?.show_no_employee || false,
  },
  {
    label: 'Fecha Inicial',
    name: 'date_ini',
    type: 'date',
    visible: false,
  },
  {
    label: 'Fecha Final',
    name: 'date_end',
    type: 'date',
    visible: false,
  },
];

export const oInitialSearch = {
  bill_type_id: undefined,
  business_name: '',
  company_id: undefined,
  date_ini: undefined,
  date_end: undefined,
  folio: '',
  no_employee: '',
  rfc: '',
  serie: '',
  status_id: undefined,
  uuid: '',
};
