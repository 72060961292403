import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export const TagSelector = ({
  aOptions,
  aSelected,
  children,
  placeholder,
  setOptions,
  setSelected,
}) => {
  const handleAdd = values => {
    const aTemp = [...aOptions];
    for (let sItem of values) {
      const nIndex = aTemp.findIndex(sValue => sValue === sItem);
      if (nIndex === -1) {
        aTemp.push(sItem);
        let aTempSelected = [...aSelected];
        aTempSelected.push(sItem);
        setSelected(aTempSelected);
      }
    }

    setOptions(aTemp);
  };

  const handleRemove = value => {
    const aTemp = [...aOptions];
    const aSelectedTemp = [...aSelected];
    const nIndex = aTemp.findIndex(sValue => sValue === value);
    const nSelectedIndex = aSelectedTemp.findIndex(sValue => sValue === value);
    if (nIndex > -1) {
      aTemp.splice(nIndex, 1);
      setOptions(aTemp);
    }

    if (nSelectedIndex > -1) {
      aSelectedTemp.splice(nSelectedIndex, 1);
      setSelected(aSelectedTemp);
    }
  };

  const handleSelect = value => {
    let aTemp = [...aSelected];
    const nIndex = aTemp.findIndex(sItem => sItem === value);
    if (nIndex === -1) {
      aTemp.push(value);
      setSelected(aTemp);
    }
  };

  return (
    <Select
      mode="tags"
      onChange={handleAdd}
      onDeselect={handleRemove}
      onSelect={handleSelect}
      optionFilterProp="label"
      placeholder={placeholder}
      tokenSeparators={[',']}
      value={aSelected}
    >
      {children}
    </Select>
  );
};

TagSelector.propTypes = {
  aOptions: PropTypes.array.isRequired,
  aSelected: PropTypes.array.isRequired,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  setOptions: PropTypes.func,
  setSelected: PropTypes.func,
};
