import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const aSearchElements = () => [
  {
    label: 'Código',
    name: 'code',
    type: 'input',
  },
  {
    label: 'Descripción',
    name: 'description',
    type: 'input',
  },
  {
    label: 'Estatus',
    name: 'status',
    type: 'select',
    // eslint-disable-next-line react/display-name
    values: () => (
      <>
        <Option value={1}>Activo</Option>
        <Option value={0}>Inactivo</Option>
      </>
    ),
  },
];

export const oInitialSearch = {
  code: '',
  description: '',
  status: undefined,
};

export const oInitialState = {
  code: '',
  description: '',
  status: 1,
};
