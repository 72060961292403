import React, { useCallback, useState } from 'react';
import { Button, Col, message, Row } from 'antd';

import { ClientForm, FXLayout, SearchBar, Table } from '../../components';
import { aColumns } from './columns';
import { aSearchElements, oInitialSearch, oInitialState } from './constants';
import { generateQueries } from '../../utils/functions';
import { process, UPDATE } from '../../services/Api';
import { useClient } from '../../hooks';

export const ClientsScreen = () => {
  const [search, setSearch] = useState(oInitialSearch);
  const [client, setClient] = useState(null);
  const [visible, setVisible] = useState(false);
  const searchElements = aSearchElements([]);
  const [oClients, loading, query] = useClient();

  const handleDelete = sId => {
    const oResponse = process(UPDATE, 'clients', { status: 0 }, { id: sId });
    if (oResponse.ok) {
      message.success('Cliente desactivado correctamente');
    }
  };

  const handleEdit = sId => {
    const clientEdit = oClients?.data.find(oItem => sId === oItem.id);
    if (clientEdit) {
      setClient(clientEdit);
      setVisible(true);
    }
  };

  const handleEnd = () => {
    setVisible(false);
    onSearch();
  };

  const handleNew = () => {
    setClient(oInitialState);
    setVisible(true);
  };

  const handleSearch = useCallback(() => {
    query(generateQueries(search, searchElements));
  }, [query]);

  const onPageChange = (nPage, nSize) => {
    if (!loading) {
      const nSkip = nPage * nSize - nSize;
      query(generateQueries(search, searchElements), nSkip);
    }
  };

  const onReset = () => {
    setSearch(oInitialSearch);
    query(generateQueries(oInitialSearch, searchElements));
  };

  const onSearch = () => query(generateQueries(search, searchElements));

  return (
    <FXLayout>
      <ClientForm
        oClient={client}
        oInit={oInitialState}
        onCancel={() => setVisible(!visible)}
        onEnd={handleEnd}
        title={client?.id ? 'Editar Cliente' : 'Nuevo Cliente'}
        visible={visible}
      />
      <Row align="middle" gutter={[20, 20]} justify="space-between">
        <Col flex="auto">
          <h3>Lista de clientes</h3>
        </Col>
        <Col style={{ textAlign: 'end' }}>
          <Button onClick={handleNew} type="primary">
            Nuevo
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={searchElements}
        handleReset={onReset}
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
      />
      <Table
        columns={aColumns(handleDelete, handleEdit)}
        data={oClients.data || []}
        loading={loading}
        pagination={{
          current: oClients.skip / oClients.limit + 1,
          locale: {
            items_per_page: 'p/página',
          },
          onChange: onPageChange,
          pageSize: oClients.limit,
          pageSizeOptions: [10, 20, 50],
          total: oClients.total,
          showSizeChanger: oClients.total > 10,
        }}
        rowKey="id"
      />
    </FXLayout>
  );
};
