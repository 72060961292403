import { persistReducer, persistStore } from 'redux-persist';
import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformCreator,
} from 'redux-persist-seamless-immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import Logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './reducers';

export const history = createBrowserHistory({
  initialEntries: [{ state: { key: 'home' } }],
});
const transformerConfig = {
  whitelistPerReducer: {
    auth: ['token', 'user', 'role'],
  },
};

const persistConfig = {
  key: 'fxn-site-service-1',
  storage,
  stateReconcilier: seamlessImmutableReconciler,
  transforms: [
    seamlessImmutableTransformCreator(transformerConfig),
    encryptTransform({
      secretKey: process.env.REACT_APP_SECURE_TOKEN || 'fxn-site-service',
      onError: function (error) {
        console.log(error);
      },
    }),
  ],
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers(history));

export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(routerMiddleware(history), Logger, thunk))
);

export const persistor = persistStore(store);
