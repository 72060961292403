import immutable from 'seamless-immutable';
import { createReducer, createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
  authClear: [],
  authFailure: ['error'],
  authRequest: [],
  authSuccess: ['token', 'user'],
});

const initialState = immutable({
  error: false,
  errorMessage: null,
  loading: false,
  token: '',
  user: {},
});

function clear() {
  return initialState;
}

function failure(state, action) {
  let { error } = action;
  return state.merge({
    error: true,
    errorMessage: error,
    loading: false,
  });
}

function request(state) {
  return state.merge({
    error: false,
    errorMessage: null,
    loading: true,
  });
}

function success(state, action) {
  let { token, user } = action;

  return state.merge({
    error: false,
    errorMessage: null,
    loading: false,
    token,
    user,
  });
}

const Handlers = {
  [Types.AUTH_CLEAR]: clear,
  [Types.AUTH_FAILURE]: failure,
  [Types.AUTH_REQUEST]: request,
  [Types.AUTH_SUCCESS]: success,
};

export const Auth = Creators;
export const authTypes = Types;
export default createReducer(initialState, Handlers);
