import { useCallback, useEffect, useState } from 'react';
import { process, FIND } from '../services/Api';

export const useBillStatus = (skip = 0, limit = 50) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStatus = useCallback(async () => {
    setLoading(true);
    const oResponse = await process(
      FIND,
      'bill-status',
      {},
      {
        limit,
        skip,
      }
    );

    setLoading(false);
    if (oResponse.ok) {
      setData(oResponse.data?.data);
    } else {
      setError(oResponse.data);
    }
  }, [process]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  return [data, loading, error];
};
