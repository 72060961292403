import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export const SearchSelector = ({
  children,
  getSelectValue,
  handleSearch,
  name,
  placeholder,
  value,
}) => {
  const handleFilterOption = (input, option) =>
    option.children
      .toString()
      .toLowerCase()
      .indexOf(input.toString().toLowerCase()) >= 0;

  return (
    <Select
      filterOption={handleFilterOption}
      name={name}
      onChange={selected => getSelectValue(name, selected)}
      optionFilterProp="children"
      onSearch={handleSearch}
      placeholder={placeholder}
      showSearch
      value={value}
    >
      {children}
    </Select>
  );
};

SearchSelector.propTypes = {
  children: PropTypes.node.isRequired,
  getSelectValue: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
};
