import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const aColumns = onDelete => [
  {
    dataIndex: ['company', 'business_name'],
    key: 'company',
    title: 'Empresa',
  },
  {
    dataIndex: ['company', 'rfc'],
    key: 'rfc',
    title: 'RFC',
  },
  {
    dataIndex: ['user', 'email'],
    key: 'user',
    title: 'Usuario',
  },
  {
    align: 'center',
    key: 'actions',
    // eslint-disable-next-line react/display-name
    render: (_, row) => {
      return (
        <div>
          <Popconfirm
            cancelText="No"
            okText="Si"
            onConfirm={() => onDelete(row.id)}
            title="Está seguro de eliminar el registro?"
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      );
    },
    title: 'Borrar',
    width: 80,
  },
];
