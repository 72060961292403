import React from 'react';
import { Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getServer } from '../../utils/url';
import { normalDate } from '../../utils/dates';

export const aColumns = token => [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Nombre Documento',
  },
  {
    dataIndex: 'date',
    key: 'date',
    title: 'Fecha',
    render: normalDate,
    defaultSortOrder: 'descend',
    sorter: {
      multiple: 1,
    },
  },
  {
    align: 'center',
    dataIndex: 'status_id',
    key: 'status_id',
    // eslint-disable-next-line react/display-name
    render: (sStatus, oRow) => {
      const sText =
        sStatus === '7' ? 'Error' : sStatus === '6' ? 'Generado' : 'Generando';

      return (
        <Tooltip placement="bottom" title={oRow?.description}>
          {sText}
        </Tooltip>
      );
    },
    title: 'Estatus',
  },
  {
    align: 'center',
    dataIndex: 'file_path',
    key: 'file_path',
    // eslint-disable-next-line react/display-name
    render: sUrl => (
      <a
        download
        href={`${getServer()}/download?token=${token}&txt=${sUrl}`}
        rel="noreferrer noopener"
        target="_blank"
      >
        <DownloadOutlined />
      </a>
    ),
    title: 'Descargar',
  },
];
