import React from 'react';
import { Badge, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const aColumns = onEdit => [
  {
    title: 'Nombre',
    dataIndex: 'full_name',
    key: 'full_name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Rol',
    dataIndex: ['role', 'name'],
    key: 'rol_id',
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    // eslint-disable-next-line react/display-name
    render: status => {
      if (status === 1) {
        return (
          <>
            <Badge color="green" />
            Activo
          </>
        );
      }

      return (
        <>
          <Badge color="red" />
          Inactivo
        </>
      );
    },
    width: 110,
  },
  {
    title: 'Acciones',
    align: 'center',
    key: 'actions',
    // eslint-disable-next-line react/display-name
    render: (_, row) => {
      return (
        <>
          <Button onClick={() => onEdit(row.id)}>
            <EditOutlined />
          </Button>
        </>
      );
    },
    width: 130,
  },
];
