import React from 'react';
import { Button } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SettingOutlined,
} from '@ant-design/icons';

export const aColumns = (onDelete, onEdit, onConf) => [
  {
    dataIndex: 'rfc',
    key: 'rfc',
    title: 'RFC',
  },
  {
    dataIndex: 'business_name',
    key: 'business_name',
    title: 'Nombre',
  },
  {
    dataIndex: 'short_name',
    key: 'short_name',
    title: 'Nombre Corto',
  },
  {
    dataIndex: 'contact_name',
    key: 'contact_name',
    title: 'Contacto',
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Estatus',
    render: nStatus => (nStatus === 1 ? 'Activo' : 'Inactivo'),
  },
  {
    // eslint-disable-next-line react/display-name
    render: (_, oRow) => (
      <>
        <Button onClick={() => onConf(oRow.id)}>
          <SettingOutlined />
        </Button>
        <Button onClick={() => onEdit(oRow.id)}>
          <EditOutlined />
        </Button>
        <Button onClick={() => onDelete(oRow.id)}>
          <DeleteOutlined />
        </Button>
      </>
    ),
    title: 'Acciones',
  },
];
