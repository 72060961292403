import React from 'react';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BankOutlined,
  FolderOutlined,
  HomeOutlined,
  IdcardOutlined,
  StopOutlined,
  ShopOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import { useAuth } from '../../hooks/Auth.hook';

export const MainMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const [{ user }] = useAuth();

  if (user?.rol === 'consult') {
    return (
      <Menu
        className="main-menu"
        defaultSelectedKeys={location.pathname}
        theme="light"
      >
        <Menu.Item
          key="/home"
          icon={<HomeOutlined />}
          onClick={() => history.push('/home')}
        >
          Inicio
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item disabled={true} key="g1">
          Comprobantes
        </Menu.Item>
        <Menu.Item
          icon={<WifiOutlined />}
          key="/issued"
          onClick={() => history.push('/issued')}
        >
          Emitidos
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Menu
      className="main-menu"
      defaultSelectedKeys={location.pathname}
      theme="light"
    >
      <Menu.Item
        key="/home"
        icon={<HomeOutlined />}
        onClick={() => history.push('/home')}
      >
        Inicio
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled={true} key="g1">
        Comprobantes
      </Menu.Item>
      <Menu.Item
        icon={<WifiOutlined />}
        key="/issued"
        onClick={() => history.push('/issued')}
      >
        Emitidos
      </Menu.Item>
      <Menu.Item
        key="/sources"
        icon={<FolderOutlined />}
        onClick={() => history.push('/sources')}
      >
        Insumos
      </Menu.Item>
      <Menu.Item
        key="/clients"
        icon={<IdcardOutlined />}
        onClick={() => history.push('/clients')}
      >
        Clientes
      </Menu.Item>
      {user?.rol === 'admin' && (
        <>
          <Menu.Item disabled={true} key="g2">
            Administración
          </Menu.Item>
          <Menu.Item
            icon={<UserOutlined />}
            key="/users"
            onClick={() => history.push('/users')}
          >
            Usuarios
          </Menu.Item>
          <Menu.Item
            icon={<ShopOutlined />}
            key="/companies"
            onClick={() => history.push('/companies')}
          >
            Empresas
          </Menu.Item>
          <Menu.Item
            icon={<BankOutlined />}
            key="/user-company"
            onClick={() => history.push('/user-company')}
          >
            Asignar Compañías
          </Menu.Item>
          <Menu.Item
            icon={<StopOutlined />}
            key="/cancellations"
            onClick={() => history.push('/cancellations')}
          >
            Tipo Cancelaciones
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};
