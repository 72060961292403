import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PublicRoute } from '..';

import routes from '../../settings/routes.json';
import {
  ClientsScreen,
  CompaniesScreen,
  ForgotScreen,
  HomeScreen,
  IssuedScreen,
  LoginScreen,
  SourcesScreen,
  UserCompaniesScreen,
  UsersScreen,
  VerifyScreen,
  CancellationsScreen,
} from '../../screens';
import { ProtectedRoute } from '../atoms/ProtectedRoute';

export const Router = () => {
  const { token } = useSelector(state => state.auth);

  return (
    <Switch>
      <PublicRoute
        component={LoginScreen}
        exact
        path={routes.LOGIN}
        token={token}
      />
      <PublicRoute
        component={ForgotScreen}
        exact
        path={routes.RECOVER}
        token={token}
      />
      <PublicRoute
        component={ForgotScreen}
        exact
        path={routes.FORGOT}
        token={token}
      />
      <ProtectedRoute
        component={ClientsScreen}
        exact
        path={routes.CLIENTS}
        token={token}
      />
      <ProtectedRoute
        component={CompaniesScreen}
        exact
        path={routes.COMPANIES}
        token={token}
      />
      <ProtectedRoute
        component={HomeScreen}
        exact
        path={routes.HOME}
        token={token}
      />
      <ProtectedRoute
        component={IssuedScreen}
        exact
        path={routes.ISSUED}
        token={token}
      />
      <ProtectedRoute
        component={SourcesScreen}
        exact
        path={routes.SOURCES}
        token={token}
      />
      <ProtectedRoute
        component={UserCompaniesScreen}
        exact
        path={routes.USER_COMPANY}
        token={token}
      />
      <ProtectedRoute
        component={CancellationsScreen}
        exact
        path={routes.CANCELLATIONS}
        token={token}
      />
      <ProtectedRoute
        component={UsersScreen}
        exact
        path={routes.USERS}
        token={token}
      />
      <PublicRoute
        component={VerifyScreen}
        exact
        path={routes.VERIFY}
        token={token}
      />
    </Switch>
  );
};
