import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { process, SAVE } from '../services/Api';

export const useDashboard = oParams => {
  const [loading, setLoading] = useState(true);
  const [oData, setData] = useState(null);
  const [params, setParams] = useState(oParams);

  const getData = useCallback(async () => {
    setLoading(true);
    let sDateInit = params.date_ini.format('YYYY-MM-DD') + ' 00:00:00',
      sDateEnd = params.date_end.format('YYYY-MM-DD') + ' 23:59:59',
      oSend = {
        ...params,
        date_ini: sDateInit,
        date_end: sDateEnd,
      };

    const oResponse = await process(SAVE, 'dashboard', oSend);
    setLoading(false);
    if (oResponse.ok) {
      setData(oResponse.data);
    } else {
      message.error('Error al consultar información');
    }
  }, [process, params]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onChangeParams = newParams => {
    setParams({ ...newParams });
  };

  return [oData, loading, onChangeParams];
};
