import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
} from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';

import {
  CancelBillForm,
  CancelInfo,
  FXLayout,
  SearchBar,
  SendByEmailForm,
  Table,
} from '../../components';
import { aColumns } from './columns';
import { aSearchElements, oInitialSearch } from './constants';
import {
  useAuth,
  useBill,
  useBillStatus,
  useBillType,
  useCancellation,
  useCompany,
} from '../../hooks';
import { generateQueries, generateSorts } from '../../utils/functions';
import { getServer } from '../../utils/url';
import { process, SAVE, UPDATE } from '../../services/Api';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const IssuedScreen = () => {
  const [drawerVisible, setDVisible] = useState(false);
  const [selectedRowKeys, setSelected] = useState([]);
  const [search, setSearch] = useState(oInitialSearch);
  const [generatingReport, setGenerating] = useState(false);
  const [generatingFile, setGeneratingFile] = useState(false);
  const [sendVisible, setSendVisible] = useState(false);
  const [sendCancel, setSendCancel] = useState(false);
  const [sId, setId] = useState(null);
  const [sortOpts, setSortOpts] = useState('$sort[date]=-1');
  const [bill, setBill] = useState(null);
  const [billInfo, setBillInfo] = useState(undefined);
  const [{ token, user }] = useAuth();
  const [oBills, loading, query, update] = useBill();
  const [oData] = useCompany();
  const [oTypes] = useBillType();
  const [aStatus] = useBillStatus();
  const [oCancellations] = useCancellation();
  const searchElements = aSearchElements(oData.data || [], user?.config);

  const handleCancel = async row => {
    setId(row.id);
    setBill(row);
    setSendCancel(true);
  };

  const onSubmitCancel = async oChanges => {
    const oResponse = await process(UPDATE, 'bills', oChanges, {
      id: sId,
    });

    if (oResponse.ok) {
      message.success('Cancelación en proceso');
      setSendCancel(false);
      update();
    } else {
      if (oResponse?.data?.message) {
        message.error(oResponse.data.message);
      } else {
        message.error('Error generando cancelación');
      }
    }
  };

  const handleDates = aDates => {
    if (aDates) {
      setSearch({
        ...search,
        date_ini: aDates[0],
        date_end: aDates[1],
      });
    }
  };

  const handleDownloadFile = (url, name, mime) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': mime,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const oUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = oUrl;
        link.setAttribute('download', name);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setGeneratingFile(false);
        setGenerating(false);
      })
      .catch(err => {
        console.log(err);
        message.error('Error generando archivo');
        setGeneratingFile(false);
        setGenerating(false);
      });
  };

  const handleSearch = useCallback(() => {
    query(generateQueries(search, searchElements), sortOpts);
  });

  const handleSend = row => {
    setId(row.id);
    setBill(row);
    setSendVisible(true);
  };

  const generateZip = () => {
    let sUrl = `${getServer()}/generate-zip?token=${token}&list=${selectedRowKeys.join(
      ','
    )}`;
    setGeneratingFile(true);
    handleDownloadFile(sUrl, 'emitidos.zip', 'application/zip');
  };

  const generateReport = () => {
    let sUrl = `${getServer()}/export-csv?token=${token}&action=bills&${generateQueries(
      search,
      searchElements,
      'filters'
    )}`;

    setGenerating(true);
    handleDownloadFile(sUrl, 'Reporte.csv', 'text/csv');
  };

  const getSearchForm = ({ name, value }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const onPageChange = (nPage, nSize) => {
    if (!loading) {
      const nSkip = nPage * nSize - nSize;
      query(generateQueries(search, searchElements), sortOpts, nSkip, nSize);
    }
  };

  const onHandleChange = (pagination, _, sorter) => {
    const sSort = generateSorts(Array.isArray(sorter) ? sorter : [sorter]),
      nSkip = pagination.current * pagination.pageSize - pagination.pageSize;

    setSortOpts(sSort);
    query(
      generateQueries(search, searchElements),
      sSort,
      nSkip,
      pagination.pageSize
    );
  };

  const onReset = () => {
    setSearch(oInitialSearch);
    query(generateQueries(oInitialSearch, searchElements), sortOpts);
  };

  const onSend = async values => {
    const oSend = {
        bill_id: sId,
        emails: values,
      },
      oResponse = await process(SAVE, 'send-bill', oSend);

    if (oResponse.ok) {
      setId(null);
      setSendVisible(false);
      message.success('Factura enviada correctamente');
    } else {
      message.error('Error al enviar la factura');
    }
  };

  const handleInfoRow = row => {
    setBillInfo(row);
  };

  const onAfterClose = () => {
    setBillInfo(undefined);
  };

  const _filterColumns = useMemo(() => {
    let pColumns = aColumns(
      handleCancel,
      handleSend,
      handleInfoRow,
      user?.rol || '',
      token
    );

    if (user.config?.show_no_employee) {
      return pColumns;
    } else {
      return pColumns.filter(oCol => oCol.dataIndex !== 'no_employee');
    }
  }, [aColumns, user]);

  return (
    <FXLayout>
      <Drawer
        title="Búsqueda Avanzada"
        onClose={() => setDVisible(false)}
        placement="right"
        visible={drawerVisible}
        width="50%"
      >
        <Form layout="vertical">
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item label="Tipo CFDI">
                <Select
                  name="bill_type_id"
                  placeholder="Seleccione"
                  onChange={value =>
                    getSearchForm({ name: 'bill_type_id', value })
                  }
                  value={search?.bill_type_id || undefined}
                >
                  {oTypes.data?.map(oType => (
                    <Option key={oType.id} value={oType.id}>
                      {oType.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Estatus CFDI">
                <Select
                  name="status_id"
                  placeholder="Seleccione"
                  onChange={value =>
                    getSearchForm({ name: 'status_id', value })
                  }
                  value={search?.status_id || undefined}
                >
                  {aStatus.map(
                    oStatus =>
                      (oStatus.apply_for === 'bills' || oStatus.id === '8') && (
                        <Option key={oStatus.id} value={oStatus.id}>
                          {oStatus.description}
                        </Option>
                      )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Fechas">
                <RangePicker
                  locale={locale}
                  onChange={handleDates}
                  value={[search?.date_ini, search?.date_end]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RFC">
                <Input
                  name="rfc"
                  onChange={({ target }) => getSearchForm(target)}
                  type="text"
                  value={search?.rfc}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Razón Social">
                <Input
                  name="business_name"
                  onChange={({ target }) => getSearchForm(target)}
                  type="text"
                  value={search?.business_name}
                />
              </Form.Item>
            </Col>
            {search?.bill_type_id === 'N' && (
              <Col span={12}>
                <Form.Item label="Número de Empleado">
                  <Input
                    name="no_employee"
                    onChange={({ target }) => getSearchForm(target)}
                    type="text"
                    value={search?.no_employee}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[10, 10]} justify="space-between">
            <Col span={10}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  handleSearch();
                  setDVisible(false);
                }}
              >
                <SearchOutlined /> Buscar
              </Button>
            </Col>
            <Col span={10}>
              <Button style={{ width: '100%' }} onClick={onReset}>
                <DeleteOutlined /> Limpiar
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
      <SendByEmailForm
        bill={bill}
        onCancel={() => setSendVisible(false)}
        onSubmit={onSend}
        visible={sendVisible}
      />
      <CancelBillForm
        types={oCancellations?.data || []}
        onCancel={() => setSendCancel(false)}
        onSubmit={onSubmitCancel}
        visible={sendCancel}
      />
      <CancelInfo
        row={billInfo}
        types={oCancellations?.data || []}
        onAfterClose={onAfterClose}
      />
      <Row align="middle" gutter={[20, 20]} justify="space-between">
        <Col flex="auto">
          <h3>Lista de CFDI</h3>
        </Col>
        <Col flex="none">
          {selectedRowKeys?.length > 0 && (
            <Button onClick={!generatingFile ? generateZip : undefined}>
              {generatingFile ? <Spin /> : 'Descargar en ZIP'}
            </Button>
          )}
          {generatingReport ? (
            <Spin />
          ) : (
            <Button style={{ marginLeft: 10 }} onClick={generateReport}>
              Exportar CSV
            </Button>
          )}
          <Button
            onClick={() => setDVisible(true)}
            type="primary"
            style={{ marginLeft: 10 }}
          >
            Búsqueda Avanzada
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={searchElements}
        handleReset={onReset}
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
      />
      <Table
        columns={_filterColumns}
        data={oBills?.data || []}
        ids={selectedRowKeys}
        loading={loading}
        pagination={{
          current: oBills.skip / oBills.limit + 1,
          locale: {
            items_per_page: 'p/página',
          },
          onChange: onPageChange,
          pageSize: oBills.limit,
          pageSizeOptions: [10, 20, 50],
          total: oBills.total,
          showSizeChanger: oBills.total > 10,
        }}
        onChange={onHandleChange}
        rowKey="id"
        setIds={setSelected}
      />
    </FXLayout>
  );
};
