import React, { useCallback, useState } from 'react';
import { Button, Col, message, Row } from 'antd';

import { CancellationForm, FXLayout, SearchBar, Table } from '../../components';
import { aSearchElements, oInitialSearch, oInitialState } from './constants';
import { generateQueries } from '../../utils/functions';
import { process, UPDATE } from '../../services/Api';
import { useCancellation } from '../../hooks';
import { aColumns } from './columns';

export const CancellationsScreen = () => {
  const [search, setSearch] = useState(oInitialSearch);
  const [cancellation, setCancellation] = useState(null);
  const [visible, setVisible] = useState(false);
  const searchElements = aSearchElements([]);
  const [oCancellations, loading, query] = useCancellation();

  const handleDelete = sId => {
    const oResponse = process(
      UPDATE,
      'cancellations',
      { status: 0 },
      { id: sId }
    );

    if (oResponse.ok) {
      message.success('Se desactivo correctamente el registro');
    }
  };

  const handleEdit = sId => {
    const cancellationEdit = oCancellations?.data.find(
      oItem => sId === oItem.id
    );
    if (cancellationEdit) {
      setCancellation(cancellationEdit);
      setVisible(true);
    }
  };

  const handleEnd = () => {
    setVisible(false);
    onSearch();
  };

  const handleNew = () => {
    setCancellation(oInitialState);
    setVisible(true);
  };

  const handleSearch = useCallback(() => {
    query(generateQueries(search, searchElements));
  }, [query]);

  const onPageChange = (nPage, nSize) => {
    if (!loading) {
      const nSkip = nPage * nSize - nSize;
      query(generateQueries(search, searchElements), nSkip, nSkip);
    }
  };

  const onReset = () => {
    setSearch(oInitialSearch);
    query(generateQueries(oInitialSearch, searchElements));
  };

  const onSearch = () => query(generateQueries(search, searchElements));

  return (
    <FXLayout>
      <CancellationForm
        oCancellation={cancellation}
        oInit={oInitialState}
        onCancel={() => setVisible(!visible)}
        onEnd={handleEnd}
        title={cancellation?.id ? 'Editar' : 'Nueva'}
        visible={visible}
      />
      <Row align="middle" gutter={[20, 20]} justify="space-between">
        <Col flex="auto">
          <h3>Tipos de Cancelaciones</h3>
        </Col>
        <Col style={{ textAlign: 'end' }}>
          <Button onClick={handleNew} type="primary">
            Nuevo
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={searchElements}
        handleReset={onReset}
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
      />
      <Table
        columns={aColumns(handleDelete, handleEdit)}
        data={oCancellations.data || []}
        loading={loading}
        pagination={{
          current: oCancellations.skip / oCancellations.limit + 1,
          locale: {
            items_per_page: 'p/página',
          },
          onChange: onPageChange,
          pageSize: oCancellations.limit,
          pageSizeOptions: [10, 20, 50],
          total: oCancellations.total,
          showSizeChanger: oCancellations.total > 10,
        }}
        rowKey="id"
      />
    </FXLayout>
  );
};
